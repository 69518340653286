import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { AgreeCookiesDetailDialogComponent } from '../agree-cookies-detail-dialog/agree-cookies-detail-dialog.component';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-agree-cookies',
  templateUrl: './agree-cookies.component.html',
  styleUrls: ['./agree-cookies.component.less']
})
export class AgreeCookiesComponent implements OnInit {

  constructor(private dialogService: DialogService,
              private shoppingCartService: ShoppingCartService,
              private stateService: StateService) { }

  private STORAGE_KEY = 'et-store-cookies-gdpr';
  private agreeTimestamp = 0;

  displayDialog: boolean;

  ngOnInit(): void {
    const cookiesAllowed = localStorage.getItem(this.STORAGE_KEY) ? JSON.parse(localStorage.getItem(this.STORAGE_KEY)) : { agreeTimestamp: 0 };
    try {
      this.agreeTimestamp = cookiesAllowed.agreeTimestamp ? cookiesAllowed.agreeTimestamp : 0;
    } catch (e) {
      this.agreeTimestamp = 0;
    }

    // Older that 30 days
    if (this.agreeTimestamp && this.agreeTimestamp < (this.now() - 2592000)) {
      this.agreeTimestamp = 0;
    } else if (this.agreeTimestamp) {
      this.agreeTimestamp = this.now();
    }

    // Show/hide dialog
    this.displayDialog = this.agreeTimestamp === 0;

    this.syncWithStorage();
  }

  openMoreInformationDialog() {
    const dialog = this.dialogService.open(AgreeCookiesDetailDialogComponent, {
      header: 'Informace o ochraně soukromí a cookies',
      width: '60%',
      contentStyle: {'max-height': '80%', overflow: 'auto'},
      baseZIndex: 10000
    });
  }

  confirmCookies() {
    this.agreeTimestamp = this.now();
    this.displayDialog = false;
    this.syncWithStorage();
  }

  isShoppingCartVisible() {
    return this.shoppingCartService.getIsShoppingCartVisible() && !this.stateService.isInOrder() && !this.stateService.isInSummary();
  }

  private syncWithStorage() {
    const cookiesAllowed = {
      agreeTimestamp: this.agreeTimestamp
    };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(cookiesAllowed));
  }

  private now() {
    return Math.floor(Date.now() / 1000);
  }
}
