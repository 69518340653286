import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { PackagesService } from '../../services/packages.service';
import { StateService } from '../../services/state.service';
import { Package } from '../../model/package';
import { Solution } from '../../model/solution';
import { ConfigurationItem } from '../../model/configuration-item';
import { TryItFreeDialogComponent } from '../try-it-free-dialog/try-it-free-dialog.component';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
  selector: 'app-shop-homepage',
  templateUrl: './shop-homepage.component.html',
  styleUrls: ['./shop-homepage.component.less']
})
export class ShopHomepageComponent implements OnInit {

  solutions: Solution[];
  packages: Package[];
  additionalPackages: Package[];
  specificItems: ConfigurationItem[];
  allItems: ConfigurationItem[];
  tags: string[] = ['Obchod', 'Personalistika', 'Finance', 'Doplňkové', 'Novinky'];
  searchTxt: string;

  constructor(private packagesService: PackagesService,
              private shoppingCartService: ShoppingCartService,
              private stateService: StateService,
              private dialogService: DialogService,
              private router: Router) {}

  ngOnInit() {
    this.loadItems();
    this.stateService.setActiveDetail(null);
    this.stateService.setPageTitle('ETIS Store');
  }

  loadItems() {
    this.packages = [];
    this.solutions = [];
    this.specificItems = [];

    this.packagesService.getItems().subscribe((items) => {
      this.allItems = items;
      this.packages = items.filter(item => !item.isSpecific && !item.isAdditional && !this.packagesService.isSolution(item)).map(item => item as Package).sort(this.sortItems);
      this.additionalPackages = items.filter(item => !item.isSpecific && item.isAdditional && !this.packagesService.isSolution(item)).map(item => item as Package).sort(this.sortItems);
      this.solutions = items.filter(item => !item.isSpecific && this.packagesService.isSolution(item)).map(item => item as Solution).sort(this.sortItems);
      this.specificItems = items.filter(item => item.isSpecific).sort(this.sortItems);
    });
  }

  openTryItFreeDialogOrOrder() {
    if (this.shoppingCartService.getItems().length) {
      this.router.navigate(['store/order']);
    } else {
      const dialog = this.dialogService.open(TryItFreeDialogComponent, {
        showHeader: false,
        width: window.innerWidth < 915 ? '95%' : '50%',
        contentStyle: {'max-height': '80%', overflow: 'auto'},
        baseZIndex: 10000,
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        styleClass: 'try-etis-dialog'
      });
    }
  }

  private sortItems(a, b) {
    return (a.isBasic ? 900000000 : a.id) - (b.isBasic ? 900000000 : b.id);
  }
}
