import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private NORMALIZE_CHARS = [' ', 'Á', 'É', 'Í', 'Ó', 'Ú', 'Ý', 'Č', 'Ď', 'Ě', 'Ň', 'Ř', 'Š', 'Ť', 'Ž', 'Ů', 'á', 'é', 'í', 'ó', 'ú', 'ý', 'č', 'ď', 'ě', 'ň', 'ř', 'š', 'ť', 'ž', 'ů'];
  private NORMALIZED_CHARS = ['-', 'A', 'E', 'I', 'O', 'U', 'Y', 'C', 'D', 'E', 'N', 'R', 'S', 'T', 'Z', 'U', 'a', 'e', 'i', 'o', 'u', 'y', 'c', 'd', 'e', 'n', 'r', 's', 't', 'z', 'u'];

  constructor() { }

  normalizeTitle(title: string) {
    let normalized: string = title;

    for (let i = 0; i < this.NORMALIZE_CHARS.length; i++) {
      normalized = normalized.split(this.NORMALIZE_CHARS[i]).join(this.NORMALIZED_CHARS[i]);
    }
    return normalized;
  }

  asyncLoop(iterations, func, callback, breakCallback) {
    let index = 0;
    let done = false;
    const loop = {
      next() {
        if (done) {
          return;
        }

        if (index < iterations) {
          index++;
          func(loop);
        } else {
          done = true;
          if (callback) {
            callback();
          }
        }
      },

      iteration() {
        return index - 1;
      },

      break() {
        done = true;
        if (breakCallback) {
          breakCallback();
        }
      }
    };
    loop.next();
    return loop;
  }
}
