import {Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'primeng/dynamicdialog';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

import { StateService } from '../../services/state.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { CartItem } from '../../model/cart-item';
import { TenantService } from '../../services/tenant.service';
import { PackagesService } from '../../services/packages.service';
import { Company } from '../../model/company';
import { AresCompany } from '../../model/ares-company';
import { Tenant } from '../../model/tenant';
import { ContactForm } from '../../model/contact-form';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';
import { GoogleTagService } from '../../services/google-tag.service';
import { ConfigurationItem } from '../../model/configuration-item';
import { CommonService } from '../../services/common.service';
import { MetaPixelService } from '../../services/meta-pixel.service';

@Component({
  selector: 'app-shop-order-form',
  templateUrl: './shop-order-form.component.html',
  styleUrls: ['./shop-order-form.component.less']
})
export class ShopOrderFormComponent implements OnInit, AfterViewInit {

  ownerContact: ContactForm;

  constructor(private elementRef: ElementRef,
              private router: Router,
              private http: HttpClient,
              private stateService: StateService,
              private shoppingCartService: ShoppingCartService,
              private tenantService: TenantService,
              private packagesService: PackagesService,
              private dialogService: DialogService,
              private googleTagService: GoogleTagService,
              private commonService: CommonService,
              public metaPixelService: MetaPixelService) {

    try {
      if (this.router.getCurrentNavigation().extras.state.owner) {
        this.ownerContact = this.router.getCurrentNavigation().extras.state.owner as ContactForm;
      }
    } catch (e) {}
  }

  packagesSections = [
    {title: 'Všeobecné', color: '#595959', packagesIds: [5, 8, 7], packages: []},
    {title: 'Obchod', color: '#4472C4', packagesIds: [3, 26, 13, 23, 22], packages: []},
    {title: 'Finance', color: '#7030A0', packagesIds: [6, 29, 18, 25], packages: []},
    {title: 'Projekty a zakázky', color: '#C00000', packagesIds: [4, 21], packages: []}
  ];
  readonly DEFAULT_PURCHASED_PACKAGES = [5, 8, 7, 3, 26, 23, 22, 6, 25, 4];

  packages: ConfigurationItem[];
  items: CartItem[];
  company: Company = {} as Company;
  tenant: Tenant = {} as Tenant;

  searchedCompanies: AresCompany[];
  searching = false;

  invalidFields: any = {};

  steps: any = {
    termsRead: false,
    captcha: false,
    creatingEtis: false,
    isCreated: false
  };

  suggestedName: any = {
    suggestedName: '',
    availableName: '',
    notAvailable: false,
    forbidden: false,
    forbiddenMessage: '',
    loading: false,
    loaded: false
  };

  emailAvailability: any = {
    available: true,
    loading: false,
    loaded: false
  };

  spawnStatus: any = {
    disabled: this.stateService.isDisabledSpawn(),
    error: false,
    queued: false,
    notAvailable: false
  };

  phoneCodes = this.commonService.getAvailablePhoneCodes();
  selectedPhoneCode = this.phoneCodes[0].code;

  GOOGLE_CAPTCHA_URL = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=initRecaptcha';
  captchaSiteKey: string;

  SUGGEST_NAME_ATTEMPTS = 5;
  SPAWN_ATTEMPTS = 10;
  AFTER_SPAWN_RESET_WAIT = 60000;

  @ViewChild('companyNameInput', { static: true }) companyNameInput: ElementRef;
  @ViewChild('companyICInput', { static: true }) companyICInput: ElementRef;
  @ViewChild('dropdownCompanyName') public dropdownCompanyName: NgbDropdown;
  @ViewChild('dropdownCompanyIC') public dropdownCompanyIC: NgbDropdown;
  @ViewChild('tenantNameInput', { static: true }) tenantNameInput: ElementRef;
  @ViewChild('tenantEmailInput', { static: true }) tenantEmailInput: ElementRef;

  ngOnInit() {
    if (!this.stateService.getActiveContact()) {
      this.router.navigate(['']);
    }

    this.stateService.setActiveDetail(null);
    this.initAresSearch();
    this.initTenantNameAvailabilitySearch();
    this.company.paymentType = 2;

    const ownerContact = this.stateService.getActiveContact();
    this.company.ownerName = ownerContact.name;
    this.company.ownerSurname = ownerContact.surname;
    this.company.ownerEmail = ownerContact.email;
    this.company.ownerPhone = ownerContact.phone;
  }

  ngAfterViewInit() {
    this.loadDefaultPackagesAndCart().then(() => {
      const captchaScript = document.createElement('script');
      captchaScript.type = 'text/javascript';
      captchaScript.src = this.GOOGLE_CAPTCHA_URL;
      this.elementRef.nativeElement.appendChild(captchaScript);

      this.googleTagService.reportBeginCheckout(
        this.getSummaryPrice(),
        this.items.map(item => this.googleTagService.toStoreItem(item.product))
      );
    });
  }

  loadDefaultPackagesAndCart() {
    return new Promise((resolve) => {
      this.shoppingCartService.clearCart();

      this.packagesService.getPackages().subscribe((items) => {
        this.packages = items;
        this.shoppingCartService.addAll(this.packages.filter(p => this.DEFAULT_PURCHASED_PACKAGES.indexOf(p.id) > -1));
        this.packagesSections.map((section) => {
          section.packagesIds.map((id) => {
            const pckg = this.packages.find(p => p.id === id);
            if (pckg) {
              section.packages.push(pckg);
            }
          });
        });

        this.items = this.shoppingCartService.getItems();
        resolve(this.items);
      }, () => {
        resolve([]);
      });
    });
  }

  getSummaryPrice() {
    return this.shoppingCartService.getSumPrice() + (this.packagesService.getBasicPackage() ? this.packagesService.getBasicPackage().price : 0);
  }

  getSummaryCustomPrice() {
    return this.shoppingCartService.getSumCustomPrice();
  }

  toggleItemPurchased(item: ConfigurationItem) {
    if (this.shoppingCartService.isInCart(item)) {
      this.shoppingCartService.remove(this.shoppingCartService.getCartItem(item));
    } else {
      this.shoppingCartService.add(item);
    }
  }

  isInCart(item: ConfigurationItem) {
    return this.shoppingCartService.isInCart(item);
  }

  openItem(item: ConfigurationItem) {
    if (this.packagesService.isSolution(item)) {
      this.router.navigate(['store/solution/' + item.normalizedTitle]);
    } else {
      this.router.navigate(['store/package/' + item.normalizedTitle]);
    }
  }

  openBasicPackage() {
    this.router.navigate(['store/package/' + this.packagesService.getBasicPackage().normalizedTitle]);
  }

  initAresSearch() {
    fromEvent(this.companyNameInput.nativeElement, 'input').pipe(
      map((event: any) => event.target.value),
      filter(res => res.length > 2), debounceTime(300), distinctUntilChanged()
    ).subscribe((text: string) => {
      this.searchAres(text, this.dropdownCompanyName);
      this.suggestTenantName(this.company.companyName, '');
    });

    fromEvent(this.companyICInput.nativeElement, 'input').pipe(
      map((event: any) => event.target.value),
      filter(res => res.length > 2), debounceTime(300), distinctUntilChanged()
    ).subscribe((text: string) => {
      this.searchAres(text, this.dropdownCompanyIC);
    });
  }

  searchAres(search, searchDropdownElem) {
    this.searching = true;
    this.commonService.searchAres(search).subscribe((companies: any) => {
      this.searchedCompanies = companies;
      searchDropdownElem.open();
      this.searching = false;
    }, () => {
      this.searching = false;
    });
  }

  initTenantNameAvailabilitySearch() {
    fromEvent(this.tenantNameInput.nativeElement, 'input').pipe(
      map((event: any) => event.target.value),
      filter(res => res.length > 0), debounceTime(800), distinctUntilChanged()
    ).subscribe((text: string) => {
      this.suggestTenantName(this.company.companyName, text);
    });

    /*fromEvent(this.tenantEmailInput.nativeElement, 'input').pipe(
      map((event: any) => event.target.value),
      filter(res => res.length > 0), debounceTime(800), distinctUntilChanged()
    ).subscribe((text: string) => {
      this.loadEmailAvailable(text);
    });*/
  }

  setCompany(company) {
    this.company.companyName = company.companyName;
    this.company.identificationNumber = company.identificationNumber;
    this.company.companyAddress = company.address;
    this.suggestTenantName(this.company.companyName, this.tenant.tenantName);
    this.googleTagService.reportAddShippingInfoEvent(
      this.getSummaryPrice(),
      this.items.map(item => this.googleTagService.toStoreItem(item.product))
    );
  }

  suggestTenantName = async (companyName, tenantName) => {
    let available = false;
    let error = false;

    this.suggestedName.loading = true;
    this.suggestedName.loaded = false;

    let tenantTmp = tenantName;
    for (let i = 0; i < this.SUGGEST_NAME_ATTEMPTS; i++) {
      if (!available && !error) {
        await this.loadSuggestedName(companyName, tenantTmp).then((suggested: any) => {
          if (!suggested) {
            error = (i >= this.SUGGEST_NAME_ATTEMPTS - 1);
          } else {
            if (i === 0) {
              this.suggestedName = suggested;

              if (this.suggestedName.notAvailable) {
                this.tenant.tenantName = this.suggestedName.suggestedName;
              } else if (!this.suggestedName.forbidden) {
                this.tenant.tenantName = this.suggestedName.availableName;
              } else if (this.suggestedName.forbidden) {
                this.tenant.tenantName = '';
                this.suggestedName.loading = false;
                this.suggestedName.loaded = true;
                return;
              }
            } else {
              this.suggestedName.availableName = suggested.availableName;
            }

            tenantTmp = this.suggestedName.availableName;

            if (!suggested.notAvailable && !suggested.forbidden && (suggested.suggestedName === suggested.availableName)) {
              available = true;
              this.suggestedName.loading = false;
              this.suggestedName.loaded = true;
              return;
            }
          }
        });
      }

      if (error) {
        this.spawnStatus.notAvailable = true;
        this.commonService.showNotificationMessage(
          'error',
          'Chyba!',
          'Naše servery v tuto chvíli nejsou dostupné, zkuste to prosím později.'
        );
      }
    }
  }

  useSuggestedName() {
    this.tenant.tenantName = this.suggestedName.availableName;
    this.suggestedName.notAvailable = false;
    this.suggestedName.forbidden = false;
  }

  private loadSuggestedName(companyName: string, tenantName: string) {
    return new Promise((resolve) => {
      this.tenantService.suggestTenantName(companyName, tenantName).subscribe((suggested) => {
        resolve(suggested);
      }, () => {
        resolve({});
      });
    });
  }

  loadEmailAvailable(email: string) {
    this.emailAvailability.loaded = false;
    this.emailAvailability.loading = true;
    this.tenantService.loadIsAvailableEmail(email).subscribe((available) => {
      this.emailAvailability.available = available;
      this.emailAvailability.loaded = true;
      this.emailAvailability.loading = false;

      if (!this.emailAvailability.available) {
        this.invalidFields.ownerEmail = 'Email již registrujeme u jiného ETIS systému';
      }
    });
  }

  openTermsDialog() {
    const dialog = this.dialogService.open(TermsDialogComponent, {
      header: 'PODMÍNKY POSKYTOVÁNÍ SOFTWARE SYSTÉM ETIS',
      width: '90%',
      contentStyle: { 'max-height': '80%', overflow: 'auto'},
      baseZIndex: 10000
    });

    dialog.onClose.subscribe((aggree) => {
      if (aggree) {
        this.setStepComplete('termsRead');
      }
    });
  }

  setStepComplete(step, complete = true) {
    if (step === 'captcha') {
      this.googleTagService.reportAddPaymentInfo(
        this.getSummaryPrice(),
        this.company.paymentType === 2 ? 'Bank Transfer' : 'Credit Card',
        this.items.map(item => this.googleTagService.toStoreItem(item.product))
      );
    }

    this.steps[step] = complete;
  }

  isStepComplete(step) {
    return !this.spawnStatus.disabled && this.steps[step];
  }

  getCaptchaSiteKey() {
    return this.stateService.getCaptchaSiteKey();
  }

  captchaExpired() {
    if (!this.isStepComplete('creatingEtis')) {
      this.setStepComplete('captcha', false);
    }
  }

  createEtis = async () => {
    if (this.validate()) {
      this.setStepComplete('creatingEtis');

      const tenant: Tenant = Object.assign({
        tenantName: this.tenant.tenantName,
        company: { companyName: this.company.companyName, identificationNumber: this.company.identificationNumber, companyAddress: this.company.companyAddress, ownerPhone: this.company.ownerPhone },
        packages: this.items.map(item => item.itemId)
      }, this.company);

      this.googleTagService.reportCreateEtisConversionEvent(this.getSummaryPrice());
      this.metaPixelService.reportCreateEtisConversionEvent(this.getSummaryPrice());
      this.googleTagService.reportPurchaseEvent(
        this.tenant.tenantName, this.getSummaryPrice(),
        this.items.map(item => this.googleTagService.toStoreItem(item.product))
      );

      this.spawnStatus.queued = false;
      this.spawnStatus.error = false;
      let success = false;
      for (let i = 0; i < this.SPAWN_ATTEMPTS; i++) {
        if (!success) {
          await this.tenantService.spawnTenant(tenant).then((created) => {
            if (!created) {
              this.spawnStatus.queued = true;
              if (i === (this.SPAWN_ATTEMPTS - 1)) {
                this.spawnStatus.error = true;
                this.commonService.showNotificationMessage(
                  'error',
                  'Chyba!',
                  'Váš systém ETIS se nepodařilo vytvořit'
                );
                setTimeout(() => {
                  this.finishAndReset();
                }, this.AFTER_SPAWN_RESET_WAIT);
              }
            } else {
              this.spawnStatus.queued = false;
              this.spawnStatus.error = false;

              setTimeout(() => {
                this.setStepComplete('isCreated');
                this.finishAndReset();
              }, this.AFTER_SPAWN_RESET_WAIT);

              success = true;
            }
          });
        }
      }
    }
  }

  getPhoneMaxLength() {
    const code = this.phoneCodes.find(item => item.code === this.selectedPhoneCode);
    return code ? code.maxLength : 9;
  }

  finishAndReset() {
    this.shoppingCartService.clearCart();
    this.router.navigate(['']);
  }

  validate() {
    if (!this.company.companyName) {
      this.invalidFields.companyName = 'Není vyplněn název Vaší společnosti';
    } else {
      delete this.invalidFields.companyName;
    }

    /*if (!this.company.companyAddress) {
      this.invalidFields.companyAddress = 'Není vyplněna adresa Vaší společnosti';
    } else {
      delete this.invalidFields.companyAddress;
    }*/

    if (!this.company.identificationNumber) {
      this.invalidFields.identificationNumber = 'Není vyplněno IČ Vaší společnosti';
    } else {
      delete this.invalidFields.identificationNumber;
    }

    if (!this.company.ownerName) {
      this.invalidFields.ownerName = 'Není vyplněno jméno u Vašeho účtu';
    } else {
      delete this.invalidFields.ownerName;
    }

    if (!this.company.ownerSurname) {
      this.invalidFields.ownerSurname = 'Není vyplněno příjmení u Vašeho účtu';
    } else {
      delete this.invalidFields.ownerSurname;
    }

    if (!this.company.ownerEmail) {
      this.invalidFields.ownerEmail = 'Není vyplněn email u Vašeho účtu';
    } else {
      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.company.ownerEmail.match(emailRegex)) {
        if (!this.emailAvailability.available) {
          this.invalidFields.ownerEmail = 'Email již registrujeme u jiného ETIS systému';
        } else {
          delete this.invalidFields.ownerEmail;
        }
      } else {
        this.invalidFields.ownerEmail = 'Vyplněný email není validní';
      }
    }

    /*if (!this.company.ownerPhone) {
      this.invalidFields.ownerPhone = 'Není vyplněn telefon u Vašeho účtu';
    } else {
      const phoneRegex = /^[0-9]+$/;
      if (this.company.ownerPhone.match(phoneRegex)) {
        if (this.company.ownerPhone.length <= this.getPhoneMaxLength()) {
          delete this.invalidFields.ownerPhone;
        } else {
          this.invalidFields.ownerPhone = 'Vyplněný telefon má příliš mnoho číslic';
        }
      } else {
        this.invalidFields.ownerPhone = 'Vyplněný telefon není validní';
      }
    }*/

    /*if (!this.company.ownerRole) {
      this.invalidFields.ownerRole = 'Není vyplněna funkce u Vašeho účtu';
    } else {
      delete this.invalidFields.ownerRole;
    }*/

    if (!this.tenant.tenantName || this.tenant.tenantName.length < 2) {
      this.invalidFields.name = 'Není vyplněn název Vašeho systému';
    } else {
      if (this.suggestedName.notAvailable || this.suggestedName.forbidden || this.suggestedName.loading) {
        this.invalidFields.name = 'Název Vašeho systému nemůže být použit';
      } else {
        delete this.invalidFields.name;
      }
    }

    if (!this.company.paymentType) {
      this.invalidFields.paymentType = 'Není zvolen způsob platby';
    } else {
      delete this.invalidFields.paymentType;
    }

    if (!this.steps.termsRead) {
      this.invalidFields.terms = 'Není potvrzeno přečtení Obchodních podmínek';
    } else {
      delete this.invalidFields.terms;
    }

    if (!this.steps.captcha) {
      this.invalidFields.captcha = 'Není potvrzena captcha';
    } else {
      delete this.invalidFields.captcha;
    }

    if (Object.keys(this.invalidFields).length > 0) {
      const messages = [];
      for (const key of Object.keys(this.invalidFields)) {
        messages.push(this.invalidFields[key]);
      }
      this.commonService.showNotificationMessage('warn', 'Formulář není vyplněn správně', 'Některá pole nejsou vyplněna, nebo obsahují nevalidní hodnoty');
      return false;
    } else {
      return true;
    }
  }
}
