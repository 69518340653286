import { Injectable } from '@angular/core';
import { CartItem } from '../model/cart-item';
import { ConfigurationItem } from '../model/configuration-item';
import { Package } from '../model/package';
import { GoogleTagService } from './google-tag.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private STORAGE_KEY = 'et-store-cart';
  private items: CartItem[] = [];
  private insertListeners = [];
  private cartTimestamp = 0;

  private isShoppingCartVisible: boolean;

  constructor(private googleTagService: GoogleTagService) {
    this.initCart();
  }

  private static getSumPriceFromItems(items: CartItem[]) {
    return items.filter(item => !item.product.customPriceFrom).reduce((a, b) => {
      return a + b.price;
    }, 0);
  }

  initCart() {
    const cart = localStorage.getItem(this.STORAGE_KEY) ? JSON.parse(localStorage.getItem(this.STORAGE_KEY)) : { items: [], cartTimestamp: this.now() };
    try {
      this.items = cart.items ? cart.items : [];
      this.cartTimestamp = cart.cartTimestamp ? cart.cartTimestamp : this.now();
    } catch (e) {
      this.items = [];
      this.cartTimestamp = this.now();
    }

    // Cart is oldest than week? Clear it
    if (this.cartTimestamp < (this.now() - 604800)) {
      this.clearCart();
    }
  }

  getItems() {
    return this.items;
  }

  add(item: ConfigurationItem) {
    const cartItem = this.addToCart(item);
    this.syncWithStorage();
    this.performInsertListeners();
    if (cartItem) {
      this.googleTagService.reportAddToCartEvent(
        item.price,
        [this.googleTagService.toStoreItem(cartItem.product)]
      );
    }
  }

  addAll(items: ConfigurationItem[]) {
    const cartItems = items.map(item => this.addToCart(item)).filter(item => item !== null);
    this.syncWithStorage();
    this.performInsertListeners();
    if (cartItems.length) {
      this.googleTagService.reportAddToCartEvent(
        ShoppingCartService.getSumPriceFromItems(cartItems),
        cartItems.map(cartItem => this.googleTagService.toStoreItem(cartItem.product))
      );
    }
  }

  private addToCart(item: ConfigurationItem) {
    if (!this.isInCart(item)) {
      const cartItem = {
        itemId: item.id,
        title: item.title,
        icon: item.icon,
        price: item.price,
        product: item
      } as CartItem;

      this.items.push(cartItem);
      return cartItem;
    }

    return null;
  }

  remove(item: CartItem) {
    const onlyPackages = this.items.filter(cartItem => !cartItem.product.hasOwnProperty('packages')).map(cartItem => cartItem.product as Package);

    const packageChildrenIds = onlyPackages.filter(pckg => pckg.parentPackageId && pckg.parentPackageId === item.itemId).map(pckg => pckg.id);
    packageChildrenIds.map((id) => {
      const childIndex = this.items.findIndex(cartItem => id === cartItem.itemId);
      this.items.splice(childIndex, 1);
    });

    const index = this.items.findIndex(cartItem => item.itemId === cartItem.itemId);
    this.items.splice(index, 1);

    this.googleTagService.reportRemoveFromCartEvent(
      item.price,
      [this.googleTagService.toStoreItem(item.product)]
    );

    this.syncWithStorage();
  }

  clearCart() {
    this.items = [];
    this.syncWithStorage();
  }

  getCartItem(item: ConfigurationItem) {
    return this.items ? this.items.find(cartItem => item.id === cartItem.itemId) : null;
  }

  isInCart(item: ConfigurationItem) {
    return this.items ? this.items.find(cartItem => item.id === cartItem.itemId) != null : false;
  }

  isSomeInCart() {
    return this.items ? this.items.length > 0 : false;
  }

  areAllInCart(items: ConfigurationItem[]) {
    return items.every(item => this.isInCart(item));
  }

  getSumPrice() {
    return ShoppingCartService.getSumPriceFromItems(this.items);
  }

  getSumCustomPrice() {
    return this.items.filter(item => item.product.customPriceFrom).reduce((a, b) => {
      return a + b.product.customPriceFrom;
    }, 0);
  }

  getSumDescription() {
    let countTxt = '';
    if (this.items.length === 1) {
      countTxt += 'balíček';
    } else if (this.items.length > 1 && this.items.length < 5) {
      countTxt += 'balíčky';
    } else {
      countTxt += 'balíčků';
    }
    return this.items.length ? 'Vaše řešení obsahuje ' + this.items.length.toString() + ' ' + countTxt + '.' : '';
  }

  addInsertListener(name, callback) {
    this.insertListeners[name] = callback;
  }

  performInsertListeners() {
    for (const listener in this.insertListeners) {
      if (this.insertListeners.hasOwnProperty(listener)) {
        this.insertListeners[listener]();
      }
    }
  }

  setIsShoppingCartVisible(visible: boolean) {
    this.isShoppingCartVisible = visible;
  }

  getIsShoppingCartVisible() {
    return this.isShoppingCartVisible;
  }

  private syncWithStorage() {
    const cart = {
      cartTimestamp: this.now(),
      items: this.items
    };
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(cart));
  }

  private now() {
    return Math.floor(Date.now() / 1000);
  }
}
