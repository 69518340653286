import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import { ConfigurationItem } from '../model/configuration-item';
import { GoogleTagService } from './google-tag.service';
import { ContactForm } from '../model/contact-form';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private activeContact: ContactForm;
  private activeDetail: ConfigurationItem;
  private productionMode: boolean;
  private disabledSpawn: boolean;
  private captchaSiteKey: string;
  private DEFAULT_PAGE_TITLE = 'ETIS';
  private PAGE_TITLES = {
    'common-functions': 'Obecné funkce',
    summary: 'Můj systém',
    order: 'Vytvoření systému',
    homepage: 'Store'
  };


  constructor(private router: Router,
              private http: HttpClient,
              private titleService: Title,
              private googleTagService: GoogleTagService,
              private metaTagsService: Meta) {

    this.productionMode = true;
    this.disabledSpawn = false;

    this.http.get('/api/env').subscribe((response: any) => {
      this.productionMode = response.production;
      this.disabledSpawn = !(!response.disabledSpawn);
      this.captchaSiteKey = response.captchaSiteKey;
      this.changePageListener();
    });
  }

  setActiveDetail(item: ConfigurationItem) {
    this.activeDetail = item;
    if (item) {
      this.titleService.setTitle(this.DEFAULT_PAGE_TITLE + ' ' + item.title);
      // this.metaTagsService.updateTag({ name: 'description', content: item.shortDescription });
      this.googleTagService.reportViewItemEvent(
        item.price,
        [this.googleTagService.toStoreItem(item)]
      );
    }
  }

  getActiveDetail(): ConfigurationItem {
    return this.activeDetail;
  }

  setPageTitle(title?: string) {
    this.titleService.setTitle((title ? title : this.DEFAULT_PAGE_TITLE));
  }

  setActiveContact(contact: ContactForm) {
    this.activeContact = contact;
  }

  getActiveContact() {
    return this.activeContact;
  }

  inProductionMode() {
    return this.productionMode;
  }

  isDisabledSpawn() {
    return this.disabledSpawn;
  }

  getCaptchaSiteKey() {
    return this.captchaSiteKey;
  }

  isOnHomepage() {
    return this.router.url === '/';
  }

  isInStore() {
    return this.router.url === '/store';
  }

  isInSummary() {
    return this.router.url === '/store/summary';
  }

  isInOrder() {
    return this.router.url === '/store/order';
  }

  isInCommonFunctionList() {
    return this.router.url === '/store/common-functions';
  }

  isOnCaseStudiesPage() {
    return this.router.url === '/case-studies';
  }

  isOnEventsPage() {
    return this.router.url === '/events';
  }

  private changePageListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // PAGE TITLE
        let page = this.router.url.replace('store/', '');
        page = page === '' ? 'homepage' : page;

        if (this.PAGE_TITLES[page]) {
          this.titleService.setTitle(this.DEFAULT_PAGE_TITLE + ' ' + this.PAGE_TITLES[page]);
        }

        // PAGE META TAGS

      }
    });
  }
}
