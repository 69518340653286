import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { ConfigurationItem } from '../../model/configuration-item';
import { PackagesService } from '../../services/packages.service';
import { StateService } from '../../services/state.service';
import { Solution } from '../../model/solution';
import { Package } from '../../model/package';

@Component({
  selector: 'app-shop-product-list',
  templateUrl: './shop-product-list.component.html',
  styleUrls: ['./shop-product-list.component.less']
})
export class ShopProductListComponent implements OnInit {

  @Input() allItems: ConfigurationItem[];
  @Input() items: ConfigurationItem[];
  @Input() searchText: string;

  contentIcons: any;

  constructor(private shoppingCartService: ShoppingCartService,
              private packagesService: PackagesService,
              private stateService: StateService,
              private router: Router) { }

  ngOnInit() {
    this.loadContentIcons();
  }

  openDetail(item: ConfigurationItem) {
    if (this.isSolution(item)) {
      this.router.navigate(['store/solution/' + item.normalizedTitle]);
    } else {
      this.router.navigate(['store/package/' + item.normalizedTitle]);
    }
  }

  loadContentIcons() {
    this.contentIcons = {};
    this.items.map((item) => {
      if (this.isSolution(item)) {
        this.contentIcons[item.id] = this.packagesService.getSolutionPackages(item as Solution).map((pckg) => {
          return { icon: pckg.icon, tooltip: pckg.title };
        });
      }
    });
  }

  toogleProductToCart(item: ConfigurationItem) {
    if (this.isInCart(item)) {
      if (this.isSolution(item)) {
        this.packagesService.getSolutionPackages(item as Solution).map((pckg) => {
          this.shoppingCartService.remove(this.shoppingCartService.getCartItem(pckg));
        });
      } else {
        this.shoppingCartService.remove(this.shoppingCartService.getCartItem(item));
      }
    } else {
      if (this.isSolution(item)) {
        this.shoppingCartService.addAll(this.packagesService.getSolutionPackages(item as Solution));
      } else {
        this.shoppingCartService.add(item);
      }
    }
  }

  isInCart(item: ConfigurationItem) {
    //return this.isSolution(item) ? this.shoppingCartService.areAllInCart(this.packagesService.getSolutionPackages(item as Solution)) : this.shoppingCartService.isInCart(item);
    return false;
  }

  isSomeInCart() {
    //return this.shoppingCartService.isSomeInCart();
    return false;
  }

  isSolution(item: ConfigurationItem) {
    return this.packagesService.isSolution(item);
  }

  isPreparing(item: ConfigurationItem) {
    return this.packagesService.isPreparing(item);
  }

  isPublished(item: ConfigurationItem) {
    return this.packagesService.isPublished(item);
  }

  isInDevMode() {
    return !this.stateService.inProductionMode();
  }

  checkPackageCanBeActivated(item: ConfigurationItem) {
    if (this.isSolution(item)) {
      return true;
    } else {
      let pckg = item as Package;
      if (!pckg.parentPackageId) {
        return true;
      } else {
        let parentPckg = this.allItems.find(item => item.id === pckg.parentPackageId);
        return (parentPckg && this.shoppingCartService.isInCart(parentPckg));
      }
    }
  }

  getItemTooltip(item: ConfigurationItem) {
    let tooltip;
    if (this.isSolution(item)) {
      tooltip = 'Tato kombinace balíčků je již ve Vašem řešení';
    } else if (item.isBasic) {
      tooltip = 'Základní balíček je automaticky součástí Vašeho řešení';
    } else {
      tooltip = 'Tento balíček je již ve Vašem řešení';
    }
    return (this.isInCart(item) || item.isBasic ? tooltip : '');
  }

  getItemCustomPriceUnitTranslated(unit: string) {
    return this.packagesService.getCustomPriceUnitTranslated(unit);
  }
}
