import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.less']
})
export class CaseStudiesComponent implements OnInit, AfterViewInit {

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // Particles effect
    const particlesScript = document.createElement('script');
    particlesScript.type = 'text/javascript';
    particlesScript.src = '../../../assets/js/particles-effect.js';
    this.elementRef.nativeElement.appendChild(particlesScript);
  }

}
