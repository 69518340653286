import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
@Injectable()
export class Ng2FilterPipe implements PipeTransform {

  /**
   *
   * @param items List of items to filter
   * @param term  a string term to compare with every property of the list
   * @param options - FilterOptions
   */
  static filter(items: Array<{ [key: string]: any }>, term: string, options: FilterOptions): Array<{ [key: string]: any }> {

    const toCompare = (options.ignoreCase !== false ? term.toLowerCase() : term);

    // tslint:disable-next-line:no-shadowed-variable
    function checkInside(item: any, term: string) {

      if (options.minLenght && term.length < options.minLenght) {
        return true;
      }

      if (typeof item === 'string' && (options.ignoreCase !== false ? item.toString().toLowerCase() : item.toString()).includes(toCompare)) {
        return true;
      }

      for (const property in item) {
        if (item[property] === null || item[property] === undefined) {
          continue;
        }
        if (typeof item[property] === 'object') {
          if (checkInside(item[property], term)) {
            return true;
          }
        } else if (options.props && options.props.length && !options.props.find(prop => prop === property)) {
          continue;
        } else if ((options && options.ignoreCase !== false ? item[property].toString().toLowerCase() : item[property].toString()).includes(toCompare)) {
          return true;
        }
      }
      return false;
    }

    return items.filter((item) => {
      return checkInside(item, term);
    });
  }

  /**
   * @param items object from array
   * @param term term's search
   * @param options - FilterOptions
   */
  transform(items: any, term: string, options: FilterOptions = {}): any {
    if (!term || !items) {
      return items;
    }
    return Ng2FilterPipe.filter(items, term, options);
  }
}

export class FilterOptions {
  minLenght?: number;
  props?: string[];
  ignoreCase?: boolean;
}
