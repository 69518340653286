import { Injectable } from '@angular/core';
import { ConfigurationItem } from '../model/configuration-item';

declare const gtag: (...args) => void;

export type StoreItem = {
  item_id: string,
  item_name: string,
  affiliation?: string,
  discount?: number,
  price?: number,
  quantity?: number,
  item_brand?: string,
  item_category?: string,
  coupon?: string
};

@Injectable({
  providedIn: 'root'
})
export class GoogleTagService {

  private DEFAULT_CURRENCY = 'CZK';

  private static googleTag(...args) {
    if (!window.hasOwnProperty('gtag')) {
      return;
    }

    gtag(...args);
  }

  private getStoreEventParams(value: number, items: StoreItem[]) {
    return {
      currency: this.DEFAULT_CURRENCY,
      value,
      items
    };
  }

  reportCreateEtisConversionEvent(value: number) {
    GoogleTagService.googleTag('event', 'conversion', {
      send_to: 'AW-767020680/4r9MCJPf540YEIid3-0C',
      value,
      currency: this.DEFAULT_CURRENCY
    });
  }

  reportContactFormSendEvent() {
    GoogleTagService.googleTag('event', 'conversion', {
      send_to: 'AW-767020680/PT3KCK6cwpoYEIid3-0C'
    });
  }

  reportViewCartEvent(value: number, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'view_cart', this.getStoreEventParams(value, items));
  }

  reportAddToCartEvent(value: number, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'add_to_cart', this.getStoreEventParams(value, items));
  }

  reportRemoveFromCartEvent(value: number, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'remove_from_cart', this.getStoreEventParams(value, items));
  }

  reportViewItemEvent(value: number, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'view_item', this.getStoreEventParams(value, items));
  }

  reportBeginCheckout(value: number, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'begin_checkout', this.getStoreEventParams(value, items));
  }

  reportAddShippingInfoEvent(value: number, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'add_shipping_info', {
      ...this.getStoreEventParams(value, items),
      shipping_tier: 'Immediate',
    });
  }

  reportAddPaymentInfo(value: number, paymentMethod: string, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'add_payment_info', {
      ...this.getStoreEventParams(value, items),
      payment_method: paymentMethod,
    });
  }

  reportPurchaseEvent(transactionId: string, value: number, items: StoreItem[]) {
    GoogleTagService.googleTag('event', 'purchase', {
      ...this.getStoreEventParams(value, items),
      transaction_id: transactionId
    });
  }

  toStoreItem(item: ConfigurationItem): StoreItem {
    return {
      item_id: item.id.toString(),
      item_name: item.title,
      price: item.price,
      quantity: 1,
      item_brand: 'Encore',
      item_category: item.hasOwnProperty('packages') ? 'ETIS Solution' : 'ETIS Package',
      affiliation: 'ETIS Store'
    };
  }
}
