import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { StateService } from './state.service';
import { ContactForm } from '../model/contact-form';
import { ContactFormDialogComponent } from '../components/contact-form-dialog/contact-form-dialog.component';
import { ContactRegistrationDialogComponent } from '../components/contact-registration-dialog/contact-registration-dialog.component';
import { EventRegistrationForm } from '../model/event-registration-form';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient,
              private router: Router,
              private stateService: StateService,
              private messageService: MessageService,
              private dialogService: DialogService) { }

  phoneCodes: any[] = [
    { code: '+420', maxLength: 9 },
    { code: '+421', maxLength: 9 }
  ];

  getAvailablePhoneCodes() {
    return this.phoneCodes;
  }

  searchAres(search: string): Observable<any> {
    return this.http.get('/api/ares?search=' + encodeURIComponent(search)).pipe(map((response: any) => {
      return response.collection;
    }));
  }

  openContactForm() {
    return new Promise((resolve) => {
      const dialog = this.dialogService.open(ContactFormDialogComponent, {
        showHeader: false,
        width: window.innerWidth < 915 ? '97%' : '50%',
        contentStyle: {'max-height': '80%', overflow: 'auto'},
        baseZIndex: 10000,
        data: {
          phoneCodes: this.phoneCodes
        }
      });

      dialog.onClose.subscribe((contactForm) => {
        resolve(!!contactForm);

        if (contactForm) {
          this.sendContactForm(contactForm).then((send) => {
            if (!send) {
              this.showNotificationMessage(
                'error',
                'Chyba!',
                'Zprávu se nyní nepodařilo odeslat, zkuste to prosím později.'
              );
            } else {
              this.showNotificationMessage(
                'success',
                'Zpráva odeslána!',
                'Děkujeme za zprávu, co nejdříve se Vám ozveme.'
              );
            }
          });
        }
      });
    });
  }

  sendContactForm(contactForm: ContactForm) {
    return new Promise((resolve) => {
      this.http.post('/api/contact-form', contactForm).subscribe((response) => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    });
  }

  sendEventRegistration(registrationForm: EventRegistrationForm) {
    return new Promise((resolve) => {
      this.http.post('/api/event-registration', registrationForm).subscribe((response) => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    });
  }

  openContactRegistration() {
      const dialog = this.dialogService.open(ContactRegistrationDialogComponent, {
        showHeader: false,
        styleClass: 'crd-dialog',
        width: window.innerWidth < 915 ? '97%' : '65%',
        contentStyle: {'max-height': '80%', overflow: 'auto'},
        baseZIndex: 10000,
        data: {
          phoneCodes: this.phoneCodes,
          contactForm: this.stateService.getActiveContact()
        }
      });

      dialog.onClose.subscribe((contactForm) => {
        if (contactForm) {
          this.stateService.setActiveContact(contactForm);
          this.router.navigate(['store/order']);
        }
      });
  }

  showNotificationMessage(level: string, title: string, message: string) {
    this.messageService.add({
      severity: level,
      summary: title,
      detail: message
    });

    setTimeout(() => {
      this.messageService.clear();
    }, 5000);
  }

  getSampleAIConversations() {
    return [
      { messages: [
          { type: 'q', content: 'Jaké úkoly máme otevřené u projektu Pioneer?' },
          { type: 'a', content: 'Pro projekt "Pioneer" jsou v současnosti otevřeny následující úkoly:<br/><br/>' +
              '1. <b>Integrace CRM - Chybějící data</b><br/>Zpracovatelé: Petra Nováková, Marek Svoboda, Jana Křížová<br/>' +
              'Fáze projektu: Implementace 2024/05<br/>' +
              'Vykázáno hodin: 3.50<br/>' +
              'Stav: V řešení<br/>' +
              'Typ činnosti: Vývoj<br/><br/>' +
              '2. <b>2P: Rozpočet projektu vs Skutečné náklady</b><br/>Zpracovatelé: Petra Nováková, Jana Křížová<br/>' +
              'Fáze projektu: Implementace 2024/05<br/>' +
              'Vykázáno hodin: 1.25<br/>' +
              'Stav: Vráceno na opravu<br/>' +
              'Typ činnosti: Finanční plánování<br/><br/>' +
              '3. <b>2P: notifikace – Upozornění na nepřečtené zprávy</b><br/>Zpracovatel: Marek Svoboda<br/>' +
              'Fáze projektu: Návrh 2024/02<br/>' +
              'Vykázáno hodin: 1.00<br/>' +
              'Stav: Kontrola<br/>' +
              'Typ činnosti: Testování a optimalizace<br/><br/>'+
              'To jsou k dnešnímu dni otevřené úkoly pro projekt "Pioneer". Pokud potřebujete více informací nebo máte zájem o konkrétní úkol, rád poskytnu další detaily.',
            isHtml: true
          }
        ]},
      { messages: [
          { type: 'q', content: 'Udělej úkol pro Evu, aby vytvořila pozvánku na plánovaný workshop.' },
          { type: 'a', content: 'Měl jste namysli uživatele Evu Horkou? Jaké přesné informace chcete v úkolu uvést? Například téma workshopu, datum a místo konání, kdy chcete mít pozvánku hotovou (pokud je nějaký konkrétní termín), a jakékoliv další informace, které by měly být v pozvánce zahrnuty.'},
          { type: 'q', content: 'Ano, úkol vytvoř pro Evu Horkou. Jedná se o workshop na téma recyklace odpadů a pozvánku potřebujeme do příštího pátku. Ostatní informace už dostala.' },
          { type: 'a', content: 'Úkol pro Evu Horkou vytvořit pozvánku na workshop na téma "Recyklace odpadů" byl úspěšně vytvořen a s termínem dokončení do příštího pátku. Můžete zkontrolovat detaily úkolu.<br/><br/>' +
              'Pokud potřebujete další pomoc nebo máte další úkoly, neváhejte mě informovat!<br/><br/>' +
              '<a href="#">Úkol: <b>UKL-0175 Vytvoření pozvánky na workshop "Recyklace odpadů"</b></a>',
            isHtml: true
          }
        ]},
      { messages: [
          { type: 'q', content: 'Jaké máme finanční výsledky za poslední 3 měsíce?' },
          { type: 'a', content: 'Naše celkové finanční výsledky za poslední 3 měsíce pro společnost "Ukázková firma s.r.o." jsou následující:<br/>' +
              '<b>Leden 2024:</b>' +
              '<ul>' +
              '<li>Měsíční příjmy: 310,200.00 Kč</li>' +
              '<li>Měsíční výdaje: 350,150.00 Kč</li>' +
              '<li>Měsíční výsledek: -39,950.00 Kč</li>' +
              '</ul>' +
              '<b>Únor 2024:</b>' +
              '<ul>' +
              '<li>Měsíční příjmy: 555,430.00 Kč</li>' +
              '<li>Měsíční výdaje: 528,740.00 Kč</li>' +
              '<li>Měsíční výsledek: 26,690.00 Kč</li>' +
              '</ul>' +
              '<b>Březen 2024:</b>' +
              '<ul>' +
              '<li>Měsíční příjmy: 469,885.00 Kč</li>' +
              '<li>Měsíční výdaje: 437,210.00 Kč</li>' +
              '<li>Měsíční výsledek: 32,675.00 Kč</li>' +
              '</ul>' +
              'Zatímco leden přinesl ztrátu, únor a březen se nám podařilo dosáhnout pozitivních finančních výsledků.',
            isHtml: true
          },
          { type: 'q', content: 'Rád bych nějaké shrnutí.' },
          { type: 'a', content: 'Po přezkoumání finančních výsledků za první čtvrtletí roku 2024 pro společnost "Ukázková firma s.r.o.", lze shrnout, že i přes ztrátový leden se nám podařilo obrátit trend a dosáhnout v následujících měsících únoru a březnu kladných finančních výsledků. Celkově byly příjmy v prvním čtvrtletí <b>1,335,515.00 Kč</b>, zatímco celkové výdaje byly <b>1,316,100.00 Kč</b>, což vede k pozitivnímu čistému výsledku <b>19,415.00 Kč</b> za toto období. Tento obrat dokazuje, že přijatá opatření k optimalizaci nákladů a zvýšení příjmů byla úspěšná a umožnila společnosti vykročit správným směrem k dosažení finanční stability a růstu.', isHtml: true }
        ]},
      { messages: [
          { type: 'q', content: 'Jaké máme faktury po splatnosti?' },
          { type: 'a', content: 'Aktuálně máme dvě faktury, které jsou označeny „Po splatnosti“:<br/><br/>' +
              '1. Faktura <b>č. 2024000278</b> je adresovaná firmě <b>Mirofront s.r.o.</b>, s celkovou hodnotou s DPH <b>138 000 Kč</b> a datem splatnosti 30.03.2024.<br/><br/>' +
              '2. Faktura <b>č. 2022000293</b> je adresovaná firmě <b>AlbaMedia.cz</b>, s celkovou hodnotou s DPH <b>3 528 Kč</b> a datem splatnosti 01.04.2024.<br/><br/>' +
              'Faktury po splatnosti jsou v celkové hodnotě <b>141 528 Kč.</b><br/>' +
              'Pokud potřebujete více informací nebo další akce ohledně těchto faktur, neváhejte mě kontaktovat.',
            isHtml: true
          },
        ]},
      { messages: [
          { type: 'q', content: 'Jaké události mám zítra v kalendáři?' },
          { type: 'a', content: 'Zítra, dne 4. října máte v kalendáři následující události:<br/><br/>' +
              '1. Schůzka <b>Představení produktu Pavel Novák</b><br/> Od <b>9:30</b> do <b>11:00</b><br/><br/>' +
              '2. Telefonát s <b>Petrem Novákem</b> ze společnosti <b>ČEZ</b><br/> Od <b>13:00</b> do <b>13:15</b><br/><br/>' +
              '3. Připomínka <b>Vyzvednout smlouvu</b><br/> Od <b>14:00</b> do <b>14:05</b>',
            isHtml: true
          },
          { type: 'q', content: 'Super, naplánuj mi na zítra od tří schůzku s klientem Jan Novotný na upřesnění smluvních podmínek, bude to tak na hodinu.' },
          { type: 'a', content: 'Schůzka s klientem Jan Novotný byla úspěšně naplánována na zítra dopoledne od 15:00 do 16:00. Zde je odkaz na záznam události.<br/><br/>' +
              '<a href="#">Schůzka: Upřesnění smluvních podmínek s klientem <b>Jan Novotný</b></a>',
            isHtml: true
          }
        ]},
      { messages: [
          { type: 'q', content: 'Připravuji smlouvu o dílo a v ní bych potřeboval navrhnout text odstavce pro výpověď smlouvy. Pomůžeš mi? Při předčasné výpovědi před termínem dodání se uhradí 60% ceny.' },
          { type: 'a', content: 'Samozřejmě, rád pomohu. Zde je návrh textu odstavce pro výpověď smlouvy v případě předčasné výpovědi ze strany objednatele:\n\n' +
              'Objednatel má právo kdykoliv bez uvedení důvodu vypovědět tuto smlouvu před termínem dodání díla. V takovém případě se však zavazuje uhradit dodavateli náhradu ve výši 60% sjednané ceny za dílo. Dodavatel je povinen vydat objednateli dosavadní práci, kterou stihl vykonat, avšak není povinen ji dokončit. Dodavatel má právo na zaplacení veškerých prací, které již byly provedeny a nákladů spojených s případným ukončením projektu. V případě, že objednatel po výpovědi uzavře s jiným dodavatelem smlouvu na provedení stejného díla, dodavatel má právo na náhradu skutečně vynaložených nákladů v souvislosti s vývojem a výrobou díla, které představují přímou a nepřímou újmu.' },
        ]},
      { messages: [
          { type: 'q', content: 'Pomůžeš mi připravit email pro klienta, který si u nás objednal dodávku a instalaci nového serveru, ale musíme odložit jeho termín na pondělí následujícího týdne? ' },
          { type: 'a', content: 'Určitě, rád vám s tím pomohu. Níže najdete jeden možný návrh emailu pro klienta:\n\n' +
              'Vážený/á [jméno klienta],\n\n' +
              'rádi bychom vám poděkovali za váš zájem o naše služby a objednávku nového serveru. S lítostí vám musíme sdělit, že jsme se v důsledku nepředvídaných okolností nemohli držet původně stanoveného termínu dodávky a instalace.\n\n' +
              'Chtěli bychom se za tuto nevyhnutelnou prodlevu omluvit a ujistit vás, že děláme maximum pro to, abychom co nejdříve dodání a instalaci vašeho serveru uskutečnili.\n\n' +
              'Nové datum dodání a instalace jsme naplánovali na pondělí následujícího týdne. Doufáme, že toto nové datum pro vás bude přijatelné a že nám dáte šanci prokázat vám kvalitu našich služeb.\n\n' +
              'Pokud máte jakékoli dotazy nebo připomínky, neváhejte nás kontaktovat. Jsme tu pro vás a rádi vám poskytneme jakoukoli potřebnou podporu.\n\n' +
              'S pozdravem,\n' +
              '[toto místo vyplňte vaším jménem nebo jménem vaší společnosti]' },
        ]},
      { messages: [
          { type: 'q', content: 'Stručná ukázka toho co umíš' },
          { type: 'a', content: 'Jsem umělá inteligence, inteligentní systém s přirozeným jazykem, který umí odpovídat na různé typy otázek a zadání. Můžu pomoci s vyhledáváním informací, poskytováním definic a vysvětlení, překládáním jazyků, rozpoznáváním entit, generováním textu a mnohými dalšími úkoly. Například, když mi dáte zadání "Kolik je 20% z 150?" odpovím vám, že 20% z 150 je 30. Pokud byste chtěli vědět, jaká je definice slova "biodiverzita", vysvětlím vám, že biodiverzita se týká rozmanitosti života na Zemi. Pokud byste chtěli vědět, jak se řekne "dobrý den" v japonštině, odpovím vám, že se to říká "konnichiwa". Doufám, že jsem vám dala stručnou ukázku toho, co umím. Pokud máte nějaké další otázky, neváhejte se zeptat!' },
        ]},
      { messages: [
          { type: 'q', content: 'Připrav mi prosím velmi zdvořilý email pro klienta, kdy mu musíme oznámit, že jeho služby budou zdraženy o 12%. Email ho musí ujistit, že naše služby jsou pro něj stále nejlepší.' },
          { type: 'a', content: 'Vážený pane/paní,\n\n' +
              's velkým respektem vám musíme sdělit, že jsme se rozhodli zvýšit ceny našich služeb o 12 %. Tuto rozhodnutí jsme nečinili lehkomyslně, ale museli jsme reagovat na narůstající náklady na výrobu a provoz.\n' +
              '\n' +
              'V tuto chvíli chceme zdůraznit, že naše společnost si velice váží vaší spolupráce a děkujeme Vám za vaši dlouhodobou důvěru v naše služby. Naše firma se společně s vámi bude snažit přizpůsobit se nové cenové politice tak, aby vaše zkušenost s námi byla stále nejlepší, jak je to jen možné.\n' +
              '\n' +
              'Jednou z hlavních priorit naší společnosti je poskytovat nejvyšší úroveň kvality a zákaznických služeb, proto můžete očekávat, že vaše služby budou stále plně zajištěny.\n' +
              '\n' +
              'Pokud máte jakékoliv dotazy nebo požadavky ohledně tohoto oznámení, neváhejte nás kdykoliv kontaktovat. S radostí vám poskytneme veškeré informace, které potřebujete.\n' +
              '\n' +
              'Děkujeme Vám za porozumění v této situaci. Jsme si jisti, že díky našemu společnému úsilí zůstaneme na dlouhou dobu spokojenými partnery.\n' +
              '\n' +
              'S pozdravem,\n' +
              '\n' +
              '[vaše jméno a název firmy] \n' },
        ]},
      { messages: [
          { type: 'q', content: 'Poraď mi, jak si mám stručně a bez negativních emocí promluvit se zaměstnancem, který si opakovaně neplní své úkoly.' },
          { type: 'a', content: 'Je důležité si dát pozor na to, jaké slovní spojení používáte a jakým tónem hovoříte. Můžete například říct následující věci:\n' +
              '\n' +
              '- "Ráda bych s tebou promluvila o tvé práci. Připadá mi, že jsou některé věci, které bychom mohli zlepšit."\n' +
              '- "Měla bych ti říct, že mi vadí, když nesplníš své úkoly. Můžeš mi říct, co se ti zdá být problémem?"\n' +
              '- "Máme společné cíle a potřebujeme, abys plnil své úkoly. Můžeš mi říct, co ti brání v jejich plnění?"\n' +
              '\n' +
              'Je důležité být konkrétní a poukázat na příklady, kdy zaměstnanec nesplnil své úkoly. Pokud možno, můžete mu nabídnout pomoc s tím, co je během jeho práce těžké. Zároveň je ale nezbytné vyjádřit své očekávání, že úkoly budou splněny a že to má pro společnost důležitost.\n' },
        ]},
      { messages: [
          { type: 'q', content: 'Očekáváme dodávku zboží ze zahraničí, které potřebujeme pro výrobu. Připrav mi prosím email v angličtině, kde se chci zeptat, jestli bylo naše zboží už odesláno.' },
          { type: 'a', content: 'Samozřejmě, navrhuji následující textaci emailu:\n' +
              '\n' +
              'Subject: Inquiry about Shipment of Goods\n' +
              '\n' +
              'Dear [Name of the Supplier],\n' +
              '\n' +
              'I hope this email finds you well. I am writing to inquire about the shipment of the goods we ordered from your company on [Date of the Purchase]. We are expecting to receive them here in [Name of the Company and Country] in the next few days to be able to continue our production process.\n' +
              '\n' +
              'Kindly let me know if the goods have already been dispatched and if you have any tracking details or reference number I can use to track the delivery. Also, please provide an estimated date of arrival so we can make necessary arrangements.\n' +
              '\n' +
              'Thank you for your timely response. We value your partnership and look forward to receiving both quality goods and services from you in the future.\n' +
              '\n' +
              'Best regards,\n' +
              '\n' +
              '[Your Name]\n' },
        ]},
      { messages: [
          { type: 'q', content: 'Za 3 dny k nám přijede japonská obchodní návštěva. Poraď mi 5 zajímavostí pro úvod jednání, které spojují Českou republiku a Japonsko.' },
          { type: 'a', content: '1. Praha je jedním z nejoblíbenějších turistických cílů Japonců v Evropě.\n' +
              '2. Významná obchodní spolupráce, zejména v oblasti automobilového průmyslu, např. spolupráce Škody Auto s japonskými výrobci nebo výroba automobilových dílů v ČR pro japonský trh.\n' +
              '3. Japonská kultura je v České republice velmi populární a můžeme vidět vliv japonského designu v českém průmyslu a umění.\n' +
              '4. Česká republika je známá svým pivem, ale také v Japonsku se pije pivo, což může být dobré téma pro diskuzi.\n' +
              '5. Historické a kulturní vztahy mezi Českou republikou a Japonskem, např. návštěva císaře Akihita v roce 2002 nebo popularita japonské kultury v ČR, jako jsou anime nebo sushi.\n' },
        ]},
      { messages: [
          { type: 'q', content: 'Příští týden otevíráme pátou pobočku naší společnosti. Připrav mi návrh krátké úvodní řeči, kde zmíníš důležitost tohoto kroku pro plnění našich závazků a zkrácení dodacích lhůt.' },
          { type: 'a', content: 'Vážení hosté,\n' +
              '\n' +
              'je mi velkou ctí vás přivítat na slavnostním otevření naší páté pobočky. Dnes zahajujeme další důležitou kapitolu naší společnosti, která nám umožní plnit naše závazky vůči zákazníkům rychleji a efektivněji.\n' +
              '\n' +
              'Otevření této pobočky je pro nás klíčovým krokem v našem růstu a rozvoji. Díky tomu budeme schopni zkrátit dodací lhůty a zlepšit naši dostupnost pro zákazníky.\n' +
              '\n' +
              'Náš cíl je poskytovat kvalitní služby a produkty našim klientům v co nejkratším možném čase a na co nejvyšší úrovni. Otevření této pobočky nám pomůže dosáhnout tohoto cíle a zlepšit celkovou úroveň našich služeb.\n' +
              '\n' +
              'S radostí vám dnes představujeme naši pátou pobočku a těšíme se na spolupráci s vámi v této nové kapitole naší společnosti.\n' +
              '\n' +
              'Děkuji vám za pozornost.\n' },
        ]},
      { messages: [
          { type: 'q', content: 'Vymysli prosím 10 vyhledávacích hesel pro naši marketingovou kampaň, která se zaměřuje na prodej čerstvých květin.' },
          { type: 'a', content: 'Rád Vám pomohu. Zde je několik návrhů:\n' +
              '\n' +
              '1. "Čerstvé květiny z celého světa"\n' +
              '2. "Nejkrásnější květiny pro tvůj dům"\n' +
              '3. "Květiny jako dárek pro každou příležitost"\n' +
              '4. "Kvalitní květiny s rychlým doručením"\n' +
              '5. "Oživte svůj domov krásnými květinami"\n' +
              '6. "Vyberte si z naší široké nabídky květin"\n' +
              '7. "Květiny pro každou sezónu a náladu"\n' +
              '8. "Květiny, které rozjasní každý den"\n' +
              '9. "Svěží květiny přímo od pěstitelů"\n' +
              '10. "Prodej květin s profesionálním přístupem"' },
        ]}
    ];
  }
}
