import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { StateService } from '../../services/state.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-shop-navigation',
  templateUrl: './shop-navigation.component.html',
  styleUrls: ['./shop-navigation.component.less']
})
export class ShopNavigationComponent implements OnInit {

  @ViewChild('systemEtisPanel') systemEtisPanel;

  scrolled = false;

  constructor(private stateService: StateService,
              private commonService: CommonService) { }

  ngOnInit(): void {}

  checkScroll() {
    this.scrolled = ((document.documentElement.scrollTop / window.innerHeight) * 100) > 20;
  }

  isOnHomepage() {
    return this.stateService.isOnHomepage();
  }

  isInShop() {
    return this.stateService.getActiveDetail() || this.stateService.isInStore();
  }

  isInCommonFunctionList() {
    return this.stateService.isInCommonFunctionList();
  }

  isInCart() {
    return this.stateService.isInSummary() || this.stateService.isInOrder();
  }

  isOnCaseStudiesPage() {
    return this.stateService.isOnCaseStudiesPage();
  }

  isOnEventsPage() {
    return this.stateService.isOnEventsPage();
  }

  openContactForm() {
    this.commonService.openContactForm();
  }

  hideSystemEtisPanel(event) {
    if (event.target.classList.value === 'sep-section-list-item link') {
      this.systemEtisPanel.hide();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    this.checkScroll();
  }
}
