import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { PackagesService } from '../../services/packages.service';
import { ConfigurationItem } from '../../model/configuration-item';
import { Package } from '../../model/package';
import { GalleryItem } from '../../model/gallery-item';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { StateService } from '../../services/state.service';
import { CustomPriceCalculation } from '../../model/custom-price-calculation';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.less']
})
export class PackageDetailComponent implements OnInit {

  constructor(private packagesService: PackagesService,
              private shoppingCartService: ShoppingCartService,
              private stateService: StateService,
              private sanitizer: DomSanitizer,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router) { }

  package: Package;
  allItems: ConfigurationItem[];
  gallery: any[];
  fullscreenGalleryIdx = -1;
  packageDescription: any = { content: '' };
  customPriceDescription: any = { content: '' };
  recommendedWith: ConfigurationItem[];

  carouselOptions = [
    {
      breakpoint: '720px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  activationErrorMessage: string;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.packagesService.getPackageByName(params.name).subscribe((pckg) => {
        this.checkPackageCanBeActivated(pckg).then((result: any) => {
          if (!result.allowed) {
            this.activationErrorMessage = result.errorMessage;
          }
          this.package = pckg;
          this.package.keyFunctions = this.package.keyFunctions.sort(this.sortById);
          this.loadRecommendedWith(this.package.recommendedWith);
          this.loadPackageHtmlDescription(this.package.longDescriptionHtml);
          this.loadCustomPriceHtmlDescription(this.package.customPriceCalculation);
          this.loadPackageGallery(this.package.gallery);
          this.stateService.setActiveDetail(this.package);

          this.packagesService.getPackages().subscribe((packages) => {
            this.allItems = packages;
          });
        });
      });
    });
  }

  loadPackageGallery(gallery: GalleryItem[]) {
    if (gallery.length) {
      let itemIdx = -1;
      this.gallery = gallery.sort(this.sortById).map((item) => {
        itemIdx++;
        if (item.videoUrl) {
          return { video: item.videoUrl, url: this.sanitizer.bypassSecurityTrustResourceUrl(item.videoUrl), idx: itemIdx };
        } else {
          return { image: item.fileUrl, idx: itemIdx };
        }
      });
    } else {
      this.gallery = [];
    }
  }

  loadRecommendedWith(recommendedWith: number[]) {
    this.recommendedWith = [];
    if (recommendedWith.length) {
      this.packagesService.getItemsByIds(recommendedWith).subscribe((items: ConfigurationItem[]) => {
        this.recommendedWith.push(...items);
      });
    }
  }

  loadPackageHtmlDescription(url: string) {
    if (url) {
      this.http.get(url, {responseType: 'text'}).subscribe((data) => {
        this.packageDescription.content = this.sanitizer.bypassSecurityTrustHtml(data);
      });
    }
  }

  loadCustomPriceHtmlDescription(customPriceCalculation: CustomPriceCalculation) {
    if (customPriceCalculation && customPriceCalculation.priceDescriptionHtml) {
      this.http.get(customPriceCalculation.priceDescriptionHtml, {responseType: 'text'}).subscribe((data) => {
        this.customPriceDescription.content = this.sanitizer.bypassSecurityTrustHtml(data);
      });
    }
  }

  addToShoppingCart(goToOrder: boolean) {
    if (!this.activationErrorMessage) {
      this.shoppingCartService.add(this.package);
      if (goToOrder) {
        this.goToOrder();
      }
    }
  }

  goToOrder() {
    this.router.navigate(['store/order']);
  }

  isInCart() {
    return this.package ? this.shoppingCartService.isInCart(this.package) : false;
  }

  isPreparing() {
    return this.packagesService.isPreparing(this.package);
  }

  isInDevMode() {
    return !this.stateService.inProductionMode();
  }

  sortById(a, b) {
    return a.id - b.id;
  }

  doubleGhostKeyFuncByCount() {
    return this.package.keyFunctions.length % 2 === 0 && this.package.keyFunctions.length % 4 !== 0;
  }

  checkPackageCanBeActivated(pckg: Package) {
    return new Promise((resolve) => {
      if (!pckg.parentPackageId) {
        resolve({allowed: true});
      } else {
        this.packagesService.getPackage(pckg.parentPackageId).subscribe((parentPackage: ConfigurationItem) => {
          if (parentPackage && this.shoppingCartService.isInCart(parentPackage)) {
            resolve({allowed: true});
          } else if (parentPackage) {
            resolve({
              allowed: false,
              errorMessage: 'Tento balíček lze aktivovat pouze spolu s balíčkem ' + parentPackage.title + '.'
            });
          } else {
            resolve({allowed: false, errorMessage: 'Nastala chyba, nebyl nalezen nadřízený balíček.'});
          }
        });
      }
    });
  }

  getItemCustomPriceUnitTranslated(unit: string) {
    return this.packagesService.getCustomPriceUnitTranslated(unit);
  }
}
