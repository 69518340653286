import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Package } from '../model/package';
import { Solution } from '../model/solution';
import { ConfigurationItem } from '../model/configuration-item';
import { CommonFunctionCategory } from '../model/common-function-category';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {

  constructor(private http: HttpClient,
              private utilityService: UtilityService) {

    // Cache load
    this.http.get(this.API_ITEMS_URL).subscribe((items: ConfigurationItem[]) => {
      this.packages = [];
      this.solutions = [];
      for (const item of items) {
        if (item.isBasic) {
          this.basicPackage = item as Package;
        }
        if (this.isSolution(item)) {
          this.solutions.push(item as Solution);
        } else {
          this.packages.push(item as Package);
        }
      }
    });
  }

  private API_PACKAGES_URL = '/api/packages';
  private API_SOLUTIONS_URL = '/api/solutions';
  private API_ITEMS_URL = '/api/items';
  private API_COMMON_FUNCTIONS_URL = '/api/common-functions';

  private ITEMS_STATUSES = {
    UNPUBLISHED: 'UNPUBLISHED',
    PREPARING: 'PREPARING',
    PRIVATE: 'PRIVATE',
    PUBLISHED: 'PUBLISHED'
  };

  private CUSTOM_PRICE_UNITS = {
    COMPANY: 'společnost'
  };

  basicPackage: Package;

  packages: Package[];
  solutions: Solution[];

  getPackages(): Observable<Package[]> {
    return this.http.get<Package[]>(this.API_PACKAGES_URL);
  }

  getPackage(id: number): Observable<Package> {
    return this.http.get<Package>(this.API_PACKAGES_URL + '/' + id);
  }

  getPackageByName(name: string): Observable<Package> {
    return this.http.get<Package>(this.API_PACKAGES_URL + '?normalizedName=' + this.utilityService.normalizeTitle(name));
  }

  getPackagesByIds(ids: number[]): Observable<Package[]> {
    return this.http.get<Package[]>(this.API_PACKAGES_URL + '?ids=' + ids.join(','));
  }

  getSolutions(): Observable<Solution[]> {
    return this.http.get<Solution[]>(this.API_SOLUTIONS_URL);
  }

  getSolution(id: number): Observable<Solution> {
    return this.http.get<Solution>(this.API_SOLUTIONS_URL + '/' + id);
  }

  getSolutionByName(name: string): Observable<Solution> {
    return this.http.get<Solution>(this.API_SOLUTIONS_URL + '?normalizedName=' + this.utilityService.normalizeTitle(name));
  }

  getSolutionsByIds(ids: number[]): Observable<Solution[]> {
    return this.http.get<Solution[]>(this.API_SOLUTIONS_URL + '?ids=' + ids.join(','));
  }

  getItems(): Observable<ConfigurationItem[]> {
    return this.http.get<ConfigurationItem[]>(this.API_ITEMS_URL);
  }

  getItemsByIds(ids: number[]): Observable<ConfigurationItem[]> {
    return this.http.get<ConfigurationItem[]>(this.API_ITEMS_URL + '?ids=' + ids.join(','));
  }

  getCommonFunctions(): Observable<CommonFunctionCategory[]> {
    return this.http.get<CommonFunctionCategory[]>(this.API_COMMON_FUNCTIONS_URL);
  }

  getBasicPackage(): Package {
    return this.basicPackage;
  }

  isSolution(item: ConfigurationItem) {
    return item.hasOwnProperty('packages');
  }

  getSolutionPackages(solution: Solution) {
    return solution.packages.sort((a, b) => a - b).map((packageId) => {
      return this.packages ? this.packages.find(item => item.id === packageId) : null;
    });
  }

  isPreparing(item: ConfigurationItem) {
    return item.status === this.ITEMS_STATUSES.PREPARING;
  }

  isPublished(item: ConfigurationItem) {
    return item.status === this.ITEMS_STATUSES.PUBLISHED;
  }

  getCustomPriceUnitTranslated(unit: string) {
    return this.CUSTOM_PRICE_UNITS[unit] ? this.CUSTOM_PRICE_UNITS[unit] : 'uživatele';
  }
}
