import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-system-etis-panel',
  templateUrl: './system-etis-panel.component.html',
  styleUrls: ['./system-etis-panel.component.less']
})
export class SystemEtisPanelComponent implements OnInit {

  constructor(private router: Router,
              private commonService: CommonService) { }

  ngOnInit(): void {
  }

  openPackage(pcgkNormalizedTitle: string) {
    this.router.navigate(['store/package/' + pcgkNormalizedTitle]);
  }

  openContactRegistration() {
    this.commonService.openContactRegistration();
  }
}
