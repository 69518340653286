import { Component, OnInit, HostListener } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { StateService } from '../../services/state.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { PackagesService } from '../../services/packages.service';
import { CartItem } from '../../model/cart-item';
import { ConfigurationItem } from '../../model/configuration-item';
import { Package } from '../../model/package';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.less']
})
export class ShoppingCartComponent implements OnInit {

  constructor(private shoppingCartService: ShoppingCartService,
              private packagesService: PackagesService,
              private stateService: StateService,
              private router: Router) {}

  items: CartItem[] = [];
  scrolledDown = false;

  animateContinue = false;

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.scrolledDown = false;
          this.checkShoppingCartVisible();
        }, 100);
      }
    });

    this.shoppingCartService.addInsertListener('cart', () => {
      this.runContinueAnimation();
      this.checkShoppingCartVisible();
    });
    this.checkShoppingCartVisible();
  }

  getItems() {
    return this.shoppingCartService.getItems();
  }

  getBasicPackage(): Package {
    return this.packagesService.getBasicPackage();
  }

  getSumDescription() {
    return this.shoppingCartService.getSumDescription();
  }

  openItem(item: ConfigurationItem) {
    if (this.isSolution(item)) {
      this.router.navigate(['store/solution/' + item.normalizedTitle]);
    } else {
      this.router.navigate(['store/package/' + item.normalizedTitle]);
    }
  }

  removeItem(item: CartItem) {
    this.shoppingCartService.remove(item);
    this.checkShoppingCartVisible();
  }

  isSolution(item: ConfigurationItem) {
    return this.packagesService.isSolution(item);
  }

  goToOrder() {
    this.router.navigate(['store/order']);
  }

  runContinueAnimation() {
    this.animateContinue = true;
    setTimeout(() => this.animateContinue = false, 1500);
  }

  showCart() {
    return !this.stateService.isInOrder() && !this.stateService.isInSummary();
  }

  checkScrollDown() {
    this.scrolledDown = (window.innerHeight + document.documentElement.scrollTop > (document.documentElement.offsetHeight - 100));
    this.checkShoppingCartVisible();
  }

  checkShoppingCartVisible() {
    this.shoppingCartService.setIsShoppingCartVisible(!this.scrolledDown && this.getItems().length && this.showCart());
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    this.checkScrollDown();
  }
}
