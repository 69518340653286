import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { ContactForm } from '../../model/contact-form';
import { GoogleTagService } from '../../services/google-tag.service';
import { MetaPixelService } from '../../services/meta-pixel.service';

@Component({
  selector: 'app-contact-form-dialog',
  templateUrl: './contact-form-dialog.component.html',
  styleUrls: ['./contact-form-dialog.component.less']
})
export class ContactFormDialogComponent implements OnInit {

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public googleTagService: GoogleTagService,
              public metaPixelService: MetaPixelService) { }

  contactForm: ContactForm = {} as ContactForm;

  invalidFields: any = {};
  submitDisabled = true;

  phoneCodes: any[];
  selectedPhoneCode: string;

  ngOnInit(): void {
    this.phoneCodes = this.config.data.phoneCodes;
    this.selectedPhoneCode = this.phoneCodes[0].code;
  }

  getPhoneMaxLength() {
    const code = this.phoneCodes.find(item => item.code === this.selectedPhoneCode);
    return code ? code.maxLength : 9;
  }

  submitContactForm() {
    this.googleTagService.reportContactFormSendEvent();
    this.metaPixelService.reportContactFormSendEvent();
    if (this.validate()) {
      const contactData = Object.assign({}, this.contactForm);
      contactData.phone = this.selectedPhoneCode + contactData.phone;
      this.closeAndSend(contactData);
    }
  }

  validate(field: string = null) {
    if (!field || field === 'name') {
      if (!this.contactForm.name) {
        this.invalidFields.name = 'Vyplňte prosím Vaše jméno';
      } else {
        delete this.invalidFields.name;
      }
    }

    if (!field || field === 'surname') {
      if (!this.contactForm.surname) {
        this.invalidFields.surname = 'Vyplňte prosím Vaše příjmení';
      } else {
        delete this.invalidFields.surname;
      }
    }

    if (!field || field === 'company') {
      if (!this.contactForm.company) {
        this.invalidFields.company = 'Vyplňte prosím název Vaší společnosti';
      } else {
        delete this.invalidFields.company;
      }
    }

    if (!field || field === 'email') {
      if (!this.contactForm.email) {
        this.invalidFields.email = 'Vyplňte prosím název Váš email';
      } else {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (this.contactForm.email.match(emailRegex)) {
          delete this.invalidFields.email;
        } else {
          this.invalidFields.email = 'Vyplněný email není validní';
        }
      }
    }

    if (!field || field === 'phone') {
      if (!this.contactForm.phone) {
        this.invalidFields.phone = 'Vyplňte prosím Váš telefon';
      } else {
        const phoneRegex = /^[0-9]+$/;
        if (this.contactForm.phone.match(phoneRegex)) {
          if (this.contactForm.phone.length <= this.getPhoneMaxLength()) {
            delete this.invalidFields.phone;
          } else {
            this.invalidFields.phone = 'Vyplněný telefon má příliš mnoho číslic';
          }
        } else {
          this.invalidFields.phone = 'Vyplněný telefon není validní';
        }
      }
    }

    this.submitDisabled = Object.keys(this.invalidFields).length > 0;
    return !this.submitDisabled;
  }

  requiredFieldsEmpty() {
    return (!this.contactForm.name || !this.contactForm.surname || !this.contactForm.company || !this.contactForm.phone || !this.contactForm.email);
  }

  closeAndSend(contactData: ContactForm): void {
    this.ref.close(contactData);
  }

  cancel(): void {
    this.ref.close();
  }
}
