import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { StateService } from '../../services/state.service';
import { ConfigurationItem } from '../../model/configuration-item';

@Component({
  selector: 'app-shop-header',
  templateUrl: './shop-header.component.html',
  styleUrls: ['./shop-header.component.less']
})
export class ShopHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private elementRef: ElementRef,
              private stateService: StateService,
              private router: Router,
              private location: Location) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const particlesScript = document.createElement('script');
    particlesScript.type = 'text/javascript';
    particlesScript.src = '../../../assets/js/particles-effect.js';
    this.elementRef.nativeElement.appendChild(particlesScript);
  }

  getActiveDetailItem(): ConfigurationItem {
    return this.stateService.getActiveDetail();
  }

  showBack() {
    return !this.stateService.isInStore();
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    if (window["pJSDom"] instanceof Array && window["pJSDom"].length > 0) {
      try {
        for (let i = 0; i < window["pJSDom"].length; i++) {
          window["pJSDom"][i].pJS.fn.vendors.destroypJS();
        }
      } catch (e) {}
      window["pJSDom"] = [];
    }
  }
}
