import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Tenant } from '../model/tenant';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private http: HttpClient) { }

  private ETIS_SPAWN_IN_PROGRESS_WAIT = 60000;

  suggestTenantName(companyName: string, tenantName: string): Observable<any> {
    return this.http.get('/api/available-tenant-name?companyName=' + (companyName ? encodeURIComponent(companyName) : '') + '&tenantName=' + (tenantName ? encodeURIComponent(tenantName) : '')).pipe(map((response: any) => {
      return response;
    }));
  }

  loadIsAvailableEmail(email: string): Observable<boolean> {
    return this.http.get('/api/available-tenant-email?email=' + encodeURIComponent(email)).pipe(map((response: any) => {
      return response.available;
    }));
  }

  spawnTenant(tenant: Tenant) {
    return new Promise((resolve) => {
      this.http.post('/api/spawn', tenant).subscribe(() => {
        resolve(true);
      }, () => {
        setTimeout(() => {
          resolve(false);
        }, this.ETIS_SPAWN_IN_PROGRESS_WAIT);
      });
    });
  }
}
