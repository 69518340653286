import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { PackagesService } from '../../services/packages.service';
import { StateService } from '../../services/state.service';
import { UtilityService } from '../../services/utility.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.less']
})
export class HomepageComponent implements OnInit, OnDestroy, AfterViewInit {

  galleryPackages: number[] = [6, 5, 4, 3, 8, 23, 7, 22];
  gallery: any[];
  mobileAppGallery: any[];
  screenshots: any;
  activeGalleryItemIdx = 0;
  galleryAutoplayTime = 5000;

  carouselOptions = [
    {
      breakpoint: '914px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '1000px',
      numVisible: 4,
      numScroll: 1
    }
  ];

  uniqueSystemPackages: number[] = [6, 3, 5, 4, 1, 8, 22, 23, 7];
  uniqueSystemIcons: any[];

  aiConversations: any[];
  activeAiConversation: any = { messages: [] };
  aiAnswering: boolean;
  aiConversationRandomized: boolean;
  asyncLoops: any[] = [];

  constructor(private elementRef: ElementRef,
              private router: Router,
              private ngZone: NgZone,
              private dialogService: DialogService,
              private packagesService: PackagesService,
              private stateService: StateService,
              private utilityService: UtilityService,
              private commonService: CommonService) { }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    // Particles effect
    const particlesScript = document.createElement('script');
    particlesScript.type = 'text/javascript';
    particlesScript.src = '../../../assets/js/particles-effect.js';
    this.elementRef.nativeElement.appendChild(particlesScript);

    // Workflow notifications images animation on scroll
    const wfImagesObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const images = entry.target.parentElement.querySelectorAll('.wf-image');
          images.forEach((image) => {
            image.classList.remove('animate__fadeOutDown');
            image.classList.add('animate__fadeInUp');
          });
        }
      });
    });
    wfImagesObserver.observe(document.querySelector('.wf-image.second'));

    // AI conversations animation on scroll
    const aiConversationObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !this.aiConversationRandomized) {
          setTimeout(() => {
            this.randomizeAIConversation();
          }, 500);
        }
      });
    });
    aiConversationObserver.observe(document.querySelector('.ai-conversation'));
  }

  init() {
    this.stateService.setPageTitle('Podnikový systém ETIS');
    this.packagesService.getItems().subscribe((items) => {
      // Gallery
      let index = -1;
      this.gallery = this.galleryPackages.map((id) => {
        index++;
        const pckg = items.find(i => i.id === id);
        return pckg ? { packageTitle: pckg.title, packageNormalizedTitle: pckg.normalizedTitle, icon: pckg.icon, color: pckg.color, idx: index, screenshotUrl: this.screenshots[pckg.id] } : {};
      }).filter(item => item.idx >= 0);

      // Unique system section icons
      index = -1;
      this.uniqueSystemIcons = this.uniqueSystemPackages.map((id) => {
        index++;
        const pckg = items.find(i => i.id === id);
        return pckg ? { packageId: id, icon: pckg.icon, color: pckg.color, idx: index } : {};
      }).filter(item => item.idx >= 0);
    });

    // Mobile app gallery
    this.mobileAppGallery = [
      { imageUrl: 'https://systemetis.cz/wp-content/uploads/Homepage/mobile-app/iphone1.png' },
      { imageUrl: 'https://systemetis.cz/wp-content/uploads/Homepage/mobile-app/iphone2.png' },
      { imageUrl: 'https://systemetis.cz/wp-content/uploads/Homepage/mobile-app/iphone3.png' },
      { imageUrl: 'https://systemetis.cz/wp-content/uploads/Homepage/mobile-app/iphone4.png' },
      { imageUrl: 'https://systemetis.cz/wp-content/uploads/Homepage/mobile-app/iphone5.png' },
      { imageUrl: 'https://systemetis.cz/wp-content/uploads/Homepage/mobile-app/iphone6.png' }
    ];

    // Screenshots
    this.screenshots = {
      3: 'https://systemetis.cz/wp-content/uploads/2022/03/obchodni-prilezitost.png',
      22: 'https://systemetis.cz/wp-content/uploads/2022/03/odeslani.png',
      23: 'https://systemetis.cz/wp-content/uploads/2022/03/tabulka-smluv.png',
      6: 'https://systemetis.cz/wp-content/uploads/2022/03/cardwall-3.png',
      4: 'https://systemetis.cz/wp-content/uploads/Projekty/K04%20Gantt%20projektu.png',
      5: 'https://systemetis.cz/wp-content/uploads/2022/03/cardwall.png',
      7: 'https://systemetis.cz/wp-content/uploads/2022/03/vytvoreni-vykazu.png',
      8: 'https://systemetis.cz/wp-content/uploads/2022/03/zadost-o-dovolenou.png',
      21: '',
      26: ''
    };

    // Load sample AI conversations
    this.aiConversations = this.commonService.getSampleAIConversations();
  }

  openPackageInNewTab(pckg: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/store/package/${pckg.packageNormalizedTitle}`])
    );

    window.open(url, '_blank');
  }

  setActiveGalleryItem(idx: number) {
    this.activeGalleryItemIdx = idx;
  }

  contactForm() {
    this.commonService.openContactForm();
  }

  contactRegistration() {
    this.commonService.openContactRegistration();
  }

  lightingMouseMoveEffect(e, className) {
    const items = document.getElementsByClassName(className);
    for (let i = 0; i < items.length; i++) {
      const item: any = items[i];
      const rect = items[i].getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      item.style.setProperty('--mouse-x', `${x}px`);
      item.style.setProperty('--mouse-y', `${y}px`);
    }
  }

  randomizeAIConversation() {
    this.aiConversationRandomized = true;
    let conversationElem = document.getElementsByClassName('ai-conversation-content')[0];

    let usedConversations = [];
    this.asyncLoops.push(this.utilityService.asyncLoop(this.aiConversations.length * 5000, (loopConversations) => {
      this.activeAiConversation = { messages: [] };

      if (usedConversations.length === this.aiConversations.length) {
        usedConversations = [];
      }

      let randomIndex = Math.floor(Math.random() * (this.aiConversations.length - 1));
      while (usedConversations.indexOf(randomIndex) >= 0) {
        randomIndex = Math.floor(Math.random() * this.aiConversations.length);
      }
      const messages = this.aiConversations[randomIndex].messages;
      usedConversations.push(randomIndex);

      this.asyncLoops.push(this.utilityService.asyncLoop(messages.length, (loopMessages) => {
        const message = messages[loopMessages.iteration()];
        const newMessage = Object.assign({}, message);
        newMessage.content = '';
        this.activeAiConversation.messages.push(newMessage);

        if (message.type === 'a') {
          this.aiAnswering = false;
        }

        this.asyncLoops.push(this.utilityService.asyncLoop(message.content.length, (loopChars) => {
          const i = loopChars.iteration();
          const char = message.content[i];

          this.ngZone.run(() => {
            setTimeout(() => {
              this.activeAiConversation.messages[this.activeAiConversation.messages.length - 1].content += char;
              if (conversationElem) {
                conversationElem.scrollTop = conversationElem.scrollHeight;
              } else {
                conversationElem = document.getElementsByClassName('ai-conversation-content')[0];
              }
              loopChars.next();
            }, Math.random() * 30);
          });
        }, () => {
          setTimeout(() => {
            this.aiAnswering = (message.type === 'q');
          }, 500);
          setTimeout(() => {
            loopMessages.next();
          }, 2000);
        }, () => {}));
      }, () => {
        setTimeout(() => {
          loopConversations.next();
        }, 8000);
      }, () => {}));
    }, () => {}, () => {}));
  }

  ngOnDestroy() {
    this.asyncLoops.map((loop) => {
      loop.break();
    });

    if (window["pJSDom"] instanceof Array && window["pJSDom"].length > 0) {
      try {
        for (let i = 0; i < window["pJSDom"].length; i++) {
          window["pJSDom"][i].pJS.fn.vendors.destroypJS();
        }
      } catch (e) {}
      window["pJSDom"] = [];
    }
  }
}
