import { Injectable } from '@angular/core';

declare const fbq: (...args) => void;

@Injectable({
  providedIn: 'root'
})
export class MetaPixelService {

  private DEFAULT_CURRENCY = 'CZK';

  private static metaPixel(...args) {
    if (!window.hasOwnProperty('fbq')) {
      return;
    }

    fbq(...args);
  }

  reportCreateEtisConversionEvent(value: number) {
    MetaPixelService.metaPixel('track', 'StartTrial', {
      value,
      currency: this.DEFAULT_CURRENCY
    });
  }

  reportContactFormSendEvent() {
    MetaPixelService.metaPixel('track', 'Lead');
  }
}
