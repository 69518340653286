import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ShopComponent } from './components/shop/shop.component';
import { ShopHomepageComponent } from './components/shop-homepage/shop-homepage.component';
import { PackageDetailComponent } from './components/package-detail/package-detail.component';
import { SolutionDetailComponent } from './components/solution-detail/solution-detail.component';
import { ShopSummaryComponent } from './components/shop-summary/shop-summary.component';
import { ShopOrderFormComponent } from './components/shop-order-form/shop-order-form.component';
import { ShopCommonFunctionsComponent } from './components/shop-common-functions/shop-common-functions.component';
import { EasyCrmComponent } from './components/easy-crm/easy-crm.component';
import { CaseStudiesComponent } from './components/case-studies/case-studies.component';
import { EncoreEventsComponent } from './components/encore-events/encore-events.component';

const routes: Routes = [
  {path: 'store', component: ShopComponent , children: [
      // {path: '', component: ShopHomepageComponent, data: { animation: { value: 'home' }}},
      // {path: 'summary', component: ShopSummaryComponent, data: { animation: { value: 'summary' }}},
      {path: 'order', component: ShopOrderFormComponent, data: { animation: { value: 'order' }}},
      {path: 'common-functions', component: ShopCommonFunctionsComponent, data: { animation: { value: 'common-functions' }}},
      {path: 'package/:name', component: PackageDetailComponent, data: { animation: { value: 'package-detail' }}},
      {path: 'solution/:name', component: SolutionDetailComponent }
    ]},
  {path: 'jednoduche-crm', component: EasyCrmComponent, data: { animation: { value: 'home' }}},
  // {path: 'case-studies', component: CaseStudiesComponent, data: { animation: { value: 'home' }}},
  {path: 'events', component: EncoreEventsComponent, data: { animation: { value: 'home' }}},
  {path: '**', component: HomepageComponent, data: { animation: { value: 'home' }}},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' }),
    CommonModule,
    FormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
