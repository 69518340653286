import { Component, OnInit } from '@angular/core';
import { PackagesService } from '../../services/packages.service';
import { StateService } from '../../services/state.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CommonFunctionCategory } from '../../model/common-function-category';
import { CommonFunction } from '../../model/common-function';
import { CommonFunctionDialogComponent } from '../common-function-dialog/common-function-dialog.component';

@Component({
  selector: 'app-shop-common-functions',
  templateUrl: './shop-common-functions.component.html',
  styleUrls: ['./shop-common-functions.component.less']
})
export class ShopCommonFunctionsComponent implements OnInit {

  categories: CommonFunctionCategory[];

  constructor(private packagesService: PackagesService,
              private stateService: StateService,
              private dialogService: DialogService) { }

  ngOnInit(): void {
    this.loadCommonFunctions();
    this.stateService.setActiveDetail(null);
  }

  loadCommonFunctions() {
    this.categories = [];

    this.packagesService.getCommonFunctions().subscribe((items) => {
      this.categories = this.fillShadowItems(items.sort(this.sortItems));
    });
  }

  openDetailDialog(item: CommonFunction) {
    if (!item.description) {
      return;
    }

    const gal = [
      { image: 'https://picsum.photos/id/' + (Math.floor(Math.random() * 1000)) + '/900/500', idx: 0 },
      { image: 'https://picsum.photos/id/' + (Math.floor(Math.random() * 1000)) + '/900/500', idx: 1 },
      { image: 'https://picsum.photos/id/' + (Math.floor(Math.random() * 1000)) + '/900/500', idx: 2 }
    ];

    const dialog = this.dialogService.open(CommonFunctionDialogComponent, {
      header: item.name,
      width: '80%',
      contentStyle: {'max-height': '80%', overflow: 'auto'},
      baseZIndex: 10000,
      data: {
        gallery: gal,
        description: item.description || 'Ahoj toto je <b>popis, muze</b> byt dlouhy je to fajn a asi i html</br>'.repeat(15)
      }
    });
  }

  hasIconEncore(category) {
    return category.icon && category.icon.toLowerCase() === 'encore logo';
  }

  private fillShadowItems(items: CommonFunctionCategory[]) {
    for (const category of items) {
      category.functions = category.functions.sort(this.sortItems);
      while (category.functions.length % 5 !== 0) {
        category.functions.push({ id: 0, name: '', description: '', color: '', icon: '' });
      }
    }
    return items;
  }

  private sortItems(a, b) {
    return a.id - b.id;
  }

}
