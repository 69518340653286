import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.less']
})
export class TermsDialogComponent implements OnInit {

  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) { }

  termsDescription: any = { content: '' };

  ngOnInit(): void {
    this.http.get('/wp-content/uploads/2021/11/smluvni_podminky_12_2021.html', {responseType: 'text'}).subscribe((data) => {
      this.termsDescription.content = this.sanitizer.bypassSecurityTrustHtml(data);
    });
  }

  cancelDialog() {
    this.ref.close();
  }

  aggreeAndClose() {
    this.ref.close({ aggree: true });
  }
}
