import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { CaptchaModule } from 'primeng/captcha';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { KeyFilterModule } from 'primeng/keyfilter';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShopHomepageComponent } from './components/shop-homepage/shop-homepage.component';
import { ShopProductListComponent } from './components/shop-product-list/shop-product-list.component';
import { PackageDetailComponent } from './components/package-detail/package-detail.component';
import { ShopHeaderComponent } from './components/shop-header/shop-header.component';
import { ShopFooterComponent } from './components/shop-footer/shop-footer.component';
import { Ng2FilterPipe } from './pipes/ng2-filter.pipe';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { ShopSummaryComponent } from './components/shop-summary/shop-summary.component';
import { ShopOrderFormComponent } from './components/shop-order-form/shop-order-form.component';
import { SolutionDetailComponent } from './components/solution-detail/solution-detail.component';
import { TermsDialogComponent } from './components/terms-dialog/terms-dialog.component';
import { ShopCommonFunctionsComponent } from './components/shop-common-functions/shop-common-functions.component';
import { ShopNavigationComponent } from './components/shop-navigation/shop-navigation.component';
import { CommonFunctionDialogComponent } from './components/common-function-dialog/common-function-dialog.component';
import { AgreeCookiesComponent } from './components/agree-cookies/agree-cookies.component';
import { AgreeCookiesDetailDialogComponent } from './components/agree-cookies-detail-dialog/agree-cookies-detail-dialog.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ShopComponent } from './components/shop/shop.component';
import { EasyCrmComponent } from './components/easy-crm/easy-crm.component';
import { TryItFreeDialogComponent } from './components/try-it-free-dialog/try-it-free-dialog.component';
import { ContactFormDialogComponent } from './components/contact-form-dialog/contact-form-dialog.component';
import { SystemEtisPanelComponent } from './components/system-etis-panel/system-etis-panel.component';
import { ContactRegistrationDialogComponent } from './components/contact-registration-dialog/contact-registration-dialog.component';
import { CaseStudiesComponent } from './components/case-studies/case-studies.component';
import { EncoreEventsComponent } from './components/encore-events/encore-events.component';
import { EncoreEventRegistrationDialogComponent } from './components/encore-event-registration-dialog/encore-event-registration-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ShopHomepageComponent,
    ShopHeaderComponent,
    ShopFooterComponent,
    ShopProductListComponent,
    PackageDetailComponent,
    ShoppingCartComponent,
    Ng2FilterPipe,
    ShopSummaryComponent,
    ShopOrderFormComponent,
    SolutionDetailComponent,
    TermsDialogComponent,
    ShopCommonFunctionsComponent,
    ShopNavigationComponent,
    CommonFunctionDialogComponent,
    AgreeCookiesComponent,
    AgreeCookiesDetailDialogComponent,
    HomepageComponent,
    ShopComponent,
    EasyCrmComponent,
    TryItFreeDialogComponent,
    ContactFormDialogComponent,
    SystemEtisPanelComponent,
    ContactRegistrationDialogComponent,
    CaseStudiesComponent,
    EncoreEventsComponent,
    EncoreEventRegistrationDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    CarouselModule,
    GalleriaModule,
    ImageModule,
    CaptchaModule,
    ToastModule,
    DynamicDialogModule,
    DialogModule,
    ButtonModule,
    MessagesModule,
    KeyFilterModule,
    OverlayPanelModule,
    NgImageFullscreenViewModule
  ],
  providers: [MessageService,DialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
