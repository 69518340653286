import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-try-it-free-dialog',
  templateUrl: './try-it-free-dialog.component.html',
  styleUrls: ['./try-it-free-dialog.component.less']
})
export class TryItFreeDialogComponent implements OnInit {

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.ref.close();
  }
}
