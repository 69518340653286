import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-common-function-dialog',
  templateUrl: './common-function-dialog.component.html',
  styleUrls: ['./common-function-dialog.component.less']
})
export class CommonFunctionDialogComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) { }

  description: any = { content: '' };
  gallery: any[];
  fullscreenGalleryIdx = -1;

  carouselOptions = [
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  ngOnInit(): void {
    this.description.content = this.sanitizer.bypassSecurityTrustHtml(this.config.data.description);
    this.gallery = this.config.data.gallery;
  }

}
