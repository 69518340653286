import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-agree-cookies-detail-dialog',
  templateUrl: './agree-cookies-detail-dialog.component.html',
  styleUrls: ['./agree-cookies-detail-dialog.component.less']
})
export class AgreeCookiesDetailDialogComponent implements OnInit {

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) { }

  ngOnInit(): void {
  }

  close() {
    this.ref.close();
  }
}
