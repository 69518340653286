import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { PackagesService } from '../../services/packages.service';
import { ConfigurationItem } from '../../model/configuration-item';
import { Package } from '../../model/package';
import { Solution } from '../../model/solution';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { StateService } from '../../services/state.service';
import { GalleryItem } from '../../model/gallery-item';

@Component({
  selector: 'app-solution-detail',
  templateUrl: './solution-detail.component.html',
  styleUrls: ['./solution-detail.component.less']
})
export class SolutionDetailComponent implements OnInit {

  constructor(private packagesService: PackagesService,
              private shoppingCartService: ShoppingCartService,
              private stateService: StateService,
              private sanitizer: DomSanitizer,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router) { }

  solution: Solution;
  packages: Package[];
  gallery: any[];
  fullscreenGalleryIdx = -1;
  solutionDescription: any = { content: '' };
  recommendedWith: ConfigurationItem[];

  carouselOptions = [
    {
      breakpoint: '720px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.packagesService.getSolutionByName(params.name).subscribe((solution) => {
        this.solution = solution;
        this.loadSolutionPackages(this.solution.packages);
        this.loadSolutionGallery(this.solution.gallery);
        this.loadRecommendedWith(this.solution.recommendedWith);
        this.loadSolutionHtmlDescription(this.solution.longDescriptionHtml);
        this.stateService.setActiveDetail(this.solution);
      });
    });
  }

  loadSolutionPackages(packagesIds: number[]) {
    this.packages = [];
    this.packagesService.getPackagesByIds(packagesIds).subscribe((packages) => {
      this.packages = packages.sort((a, b) => a.id - b.id);
    });
  }

  loadSolutionGallery(gallery: GalleryItem[]) {
    if (gallery.length) {
      let itemIdx = -1;
      this.gallery = gallery.sort(this.sortById).map((item) => {
        itemIdx++;
        if (item.videoUrl) {
          return { video: item.videoUrl, url: this.sanitizer.bypassSecurityTrustResourceUrl(item.videoUrl), idx: itemIdx };
        } else {
          return { image: item.fileUrl, idx: itemIdx };
        }
      });
    } else {
      this.gallery = [];
    }
  }

  loadRecommendedWith(recommendedWith: number[]) {
    this.recommendedWith = [];
    if (recommendedWith.length) {
      this.packagesService.getItemsByIds(recommendedWith).subscribe((items: ConfigurationItem[]) => {
        this.recommendedWith.push(...items);
      });
    }
  }

  loadSolutionHtmlDescription(url: string) {
    if (url) {
      this.http.get(url, {responseType: 'text'}).subscribe((data) => {
        this.solutionDescription.content = this.sanitizer.bypassSecurityTrustHtml(data);
      });
    }
  }

  addToShoppingCart(goToOrder: boolean) {
    this.shoppingCartService.addAll(this.packagesService.getSolutionPackages(this.solution));
    if (goToOrder) {
      this.goToOrder();
    }
  }

  goToOrder() {
    this.router.navigate(['store/order']);
  }

  isInCart() {
    return this.solution ? this.shoppingCartService.areAllInCart(this.packages) : false;
  }

  isPreparing() {
    return this.packagesService.isPreparing(this.solution);
  }

  isInDevMode() {
    return !this.stateService.inProductionMode();
  }

  sortById(a, b) {
    return a.id - b.id;
  }

  doubleGhostKeyFuncByCount() {
    return this.packages.length % 2 === 0 && this.packages.length % 4 !== 0;
  }
}
