import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../../services/state.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { CartItem } from '../../model/cart-item';
import { PackagesService } from '../../services/packages.service';
import { ConfigurationItem } from '../../model/configuration-item';
import { Package } from '../../model/package';
import { GoogleTagService } from '../../services/google-tag.service';

@Component({
  selector: 'app-shop-summary',
  templateUrl: './shop-summary.component.html',
  styleUrls: ['./shop-summary.component.less']
})
export class ShopSummaryComponent implements OnInit {

  constructor(private stateService: StateService,
              private packagesService: PackagesService,
              private shoppingCartService: ShoppingCartService,
              private router: Router,
              private googleTagService: GoogleTagService) { }

  items: CartItem[];
  detailedKeyFunctions = false;
  itemsKeyFunctions: {[key: string]: any[]} = {};

  ngOnInit() {
    this.stateService.setActiveDetail(null);
    this.loadCartItems();
    this.googleTagService.reportViewCartEvent(
      this.getSummaryPrice(),
      this.items.map(item => this.googleTagService.toStoreItem(item.product))
    );
  }

  loadCartItems() {
    this.items = this.shoppingCartService.getItems();
    for (const item of this.items) {
      this.itemsKeyFunctions[item.itemId] = item.product.keyFunctions.filter(kf => !kf.color).sort((a, b) => {
        return a.id - b.id;
      });
    }
  }

  removeFromCart(item: CartItem) {
    this.shoppingCartService.remove(item);
    this.loadCartItems();
  }

  confirmSummary() {
    this.router.navigate(['store/order']);
  }

  getSummaryPrice() {
    return this.shoppingCartService.getSumPrice() + this.packagesService.getBasicPackage().price;
  }

  getSummaryCustomPrice() {
    return this.shoppingCartService.getSumCustomPrice();
  }

  getBasicPackage(): Package {
    return this.packagesService.getBasicPackage();
  }

  openItemDetail(item: ConfigurationItem) {
    if (this.isSolution(item)) {
      this.router.navigate(['store/solution/' + item.title]);
    } else {
      this.router.navigate(['store/package/' + item.title]);
    }
  }

  getItemCustomPriceUnitTranslated(unit: string) {
    return this.packagesService.getCustomPriceUnitTranslated(unit);
  }

  isSolution(item: ConfigurationItem) {
    return this.packagesService.isSolution(item);
  }
}
