import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';

import { EncoreEvent } from '../../model/encore-event';
import { EncoreEventRegistrationDialogComponent } from '../encore-event-registration-dialog/encore-event-registration-dialog.component';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-encore-events',
  templateUrl: './encore-events.component.html',
  styleUrls: ['./encore-events.component.less']
})
export class EncoreEventsComponent implements OnInit, AfterViewInit {

  constructor(private elementRef: ElementRef,
              private http: HttpClient,
              private dialogService: DialogService,
              private commonService: CommonService) { }

  // TEMPLATE !!!
  /*events: EncoreEvent[] = [
    { id: 1,
      title: 'Obchodní procesy a jejich automatizace',
      description: 'Automatizace obchodních procesů, vedení a řízení obchodních aktivit, zvýšení efektivity a produktivity.',
      eventDate: 1725970932,
      timeStart: '14:00',
      timeEnd: '15:00',
      price: 0,
      location: '',
      online: true }
  ];*/

  eventYearGroups = {};

  objectKeys = Object.keys;

  ngOnInit(): void {
    this.loadEvents();
  }

  ngAfterViewInit() {
    // Particles effect
    const particlesScript = document.createElement('script');
    particlesScript.type = 'text/javascript';
    particlesScript.src = '../../../assets/js/particles-effect.js';
    this.elementRef.nativeElement.appendChild(particlesScript);
  }

  loadEvents() {
    const now = Date.now() / 1000;
    this.eventYearGroups = {};

    this.http.get('/api/encore-events').subscribe((events: EncoreEvent[]) => {
      events.sort((a, b) => a.id - b.id).map((event) => {
        if (event.eventDate > now) {
          const eventYear = new Date(event.eventDate * 1000).getFullYear();
          if (!this.eventYearGroups.hasOwnProperty(eventYear)) {
            this.eventYearGroups[eventYear] = [];
          }

          this.eventYearGroups[eventYear].push(Object.assign({formattedEventDate: this.formatEventDateOnly(event.eventDate)}, event));
        }
      });
    });
  }

  formatEventDateOnly(timestamp: number) {
    const eventDate = new Date(timestamp * 1000);
    return eventDate.getDate() + '.' + (eventDate.getMonth() + 1) + '.';
  }

  formatEventDate(timestamp: number) {
    const eventDate = new Date(timestamp * 1000);
    return eventDate.getDate() + '.' + (eventDate.getMonth() + 1) + '.' + eventDate.getFullYear();
  }

  joinEvent(event: EncoreEvent) {
    return new Promise((resolve) => {
      const dialog = this.dialogService.open(EncoreEventRegistrationDialogComponent, {
        showHeader: false,
        width: window.innerWidth < 915 ? '97%' : '50%',
        contentStyle: {'max-height': '80%', overflow: 'auto'},
        baseZIndex: 10000,
        data: {
          phoneCodes: this.commonService.getAvailablePhoneCodes(),
          eventTitle: this.formatEventDate(event.eventDate) + ' - ' + event.title
        }
      });

      dialog.onClose.subscribe((registrationForm) => {
        resolve(!!registrationForm);

        if (registrationForm) {
          registrationForm.eventTitle = event.title;
          registrationForm.eventDate = event.eventDate;
          registrationForm.eventStart = event.timeStart;
          registrationForm.eventEnd = event.timeEnd;
          registrationForm.eventLocation = event.online ? 'Online' : event.location;
          registrationForm.eventPrice = event.price ? event.price + ' Kč' : 'Zdarma';
          this.commonService.sendEventRegistration(registrationForm).then((send) => {
            if (!send) {
              this.commonService.showNotificationMessage(
                'error',
                'Chyba!',
                'Registraci se nyní nepodařilo odeslat, zkuste to prosím později.'
              );
            } else {
              this.commonService.showNotificationMessage(
                'success',
                'Registrace odeslána!',
                'Děkujeme za registraci na událost.'
              );
            }
          });
        }
      });
    });
  }
}
